import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../Main";
import Breakdown from "../formParts/Breakdown";
import LedRequirements from "../formParts/LedRequirements";
import Loader from "../UI/Loader";
import prebuildData from "../../data.json";
import { LED_LIGHTING } from "../../utils/utils";

export default function LedLighting() {
  const {
    setForm,
    setFormType,
    setServiceData,
    step,
    setStep,
    setForwardStep,
    setBackStep,
    setForwardStepTitle,
    breakdown,
    setBreakdown,
    formDefaultValues,
    setFormDefaultValues,
  } = useContext(AppContext);

  //useState to store endpoint data
  const [simProData, setSimProData] = useState({
    convert: [],
    dimmer: [],
    bc: [],
    es: [],
    ses: [],
    firstHour: [],
  });

  // state to manage loading of data from SimPro
  const [loaded, setLoaded] = useState(false);

  // React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValues ? formDefaultValues : {},
  });

  const formRef = useRef();

  useEffect(() => setFormType(LED_LIGHTING));

  useEffect(() => {
    if (formDefaultValues) {
      // Form has been submitted so user has navigated back from after form submission
      setStep(1);
      setBackStep(() => () => setStep(0));
      setForwardStep(() => () => setForm(9));
    }
  }, [formDefaultValues]);

  const spotlightsFormValue = watch("spotlights");
  const dimmersFormValue = watch("dimmers");
  const bcFormValue = watch("bc");
  const sesFormValue = watch("ses");
  const esFormValue = watch("es");

  useEffect(() => {
    setForwardStepTitle("Next");
    setBackStep(undefined);
    switch (step) {
      case 0:
        setBackStep(undefined);
        setForwardStepTitle("Get my quote");
        if (
          spotlightsFormValue > 0 ||
          dimmersFormValue > 0 ||
          bcFormValue > 0 ||
          sesFormValue > 0 ||
          esFormValue > 0
        ) {
          setForwardStep(
            () => () =>
              formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              )
          );
        } else {
          setForwardStep(undefined);
        }
        break;
      case 1:
        setBackStep(() => () => setStep(0));
        setForwardStep(() => () => setForm(9));
        break;
      default:
        break;
    }
  }, [
    bcFormValue,
    dimmersFormValue,
    esFormValue,
    sesFormValue,
    setBackStep,
    setForm,
    setForwardStep,
    setForwardStepTitle,
    setStep,
    spotlightsFormValue,
    step,
  ]);

  // fetch data from SimPro
  useEffect(() => {
    prebuildData.forEach((prebuild) => {
      if (prebuild.ID === 47710) {
        setSimProData((prevState) => ({
          ...prevState,
          convert: prebuild,
        }));
      }
      if (prebuild.ID === 47707) {
        setSimProData((prevState) => ({
          ...prevState,
          dimmer: prebuild,
        }));
      }
      if (prebuild.ID === 47708) {
        setSimProData((prevState) => ({
          ...prevState,
          bc: prebuild,
        }));
      }
      if (prebuild.ID === 47709) {
        setSimProData((prevState) => ({
          ...prevState,
          es: prebuild,
        }));
      }
      if (prebuild.ID === 47711) {
        setSimProData((prevState) => ({
          ...prevState,
          ses: prebuild,
        }));
      }
      if (prebuild.ID === 55828) {
        setSimProData((prevState) => ({
          ...prevState,
          firstHour: prebuild,
        }));
      }
    });
    setLoaded(true);
  }, []);

  const onSubmit = (data) => {
    function calculateTotals() {
      // array to store breakdown of user choices
      let breakdown = [];

      if (data.spotlights > 0) {
        breakdown.push({
          name: "Convert exisiting light to LED",
          qty: data.spotlights,
          price: data.spotlights * simProData?.convert.TotalEx,
          time: simProData?.convert?.LaborTime * data.spotlights,
          prebuildIds: [simProData?.convert.ID],
        });
      }

      if (data.dimmers > 0) {
        breakdown.push({
          name: "Supply & install LED dimmer module",
          qty: data.dimmers,
          price: data.dimmers * simProData?.dimmer.TotalEx,
          time: simProData?.dimmer?.LaborTime * data.dimmers,
          prebuildIds: [simProData?.dimmer.ID],
        });
      }

      if (data.bc > 0) {
        breakdown.push({
          name: "Supply & install BC LED lamp",
          qty: data.bc,
          price: data.bc * simProData?.bc.TotalEx,
          time: simProData?.bc?.LaborTime * data.bc,
          prebuildIds: [simProData?.bc.ID],
        });
      }
      if (data.ses > 0) {
        breakdown.push({
          name: "Supply & install SES LED lamp",
          qty: data.ses,
          price: data.ses * simProData?.ses.TotalEx,
          time: simProData?.ses?.LaborTime * data.ses,
          prebuildIds: [simProData?.ses.ID],
        });
      }
      if (data.es > 0) {
        breakdown.push({
          name: "Supply & install ES LED lamp",
          qty: data.es,
          price: data.es * simProData?.es.TotalEx,
          time: simProData?.es?.LaborTime * data.es,
          prebuildIds: [simProData?.es.ID],
        });
      }

      function checkForFirstHour() {
        let total = 0;
        breakdown.forEach((item) => {
          total += item.price;
        });
        return total;
      }

      if (checkForFirstHour() < 135) {
        breakdown.push({
          name: "First Hour Charge",
          qty: 1,
          price: simProData?.firstHour.TotalEx,
          time: simProData?.firstHour?.LaborTime,
          prebuildIds: [simProData?.firstHour.ID],
        });
      }

      return breakdown;
    }

    setBreakdown(calculateTotals());
    setStep(1);
    setServiceData({
      isRewiring: false,
      data,
      breakdown: calculateTotals(),
    });
    setFormDefaultValues(data);
  };

  //Conditional component to switch between journey steps
  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <LedRequirements
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValueSpotlights={formDefaultValues?.spotlights}
            defaultValueDimmers={formDefaultValues?.dimmers}
            defaultValueBc={formDefaultValues?.bc}
            defaultValueSes={formDefaultValues?.ses}
            defaultValueEs={formDefaultValues?.es}
          />
        );
      case 1:
        return (
          <Breakdown
            formName={LED_LIGHTING}
            breakdown={breakdown}
            watch={watch}
          />
        );
      default:
        return <LedRequirements register={register} />;
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {/* Component switcher */}
        {loaded ? conditionalComponent() : <Loader />}
      </form>
    </div>
  );
}
