import Quantity from "../UI/Quantity";
import DownlightImage from "../../images/products/White Downlight.png";
import DimmerImage from "../../images/products/1 Gang Dimmer Switch.jpg";
import BCImage from "../../images/products/BC LED Lamp.jpg";
import SESImage from "../../images/products/SES LED Lamp.jpg";
import ESImage from "../../images/products/ES LED LAMP.jpg";
export default function LedRequirements({
  register,
  setValue,
  defaultValueSpotlights,
  defaultValueBc,
  defaultValueSes,
  defaultValueEs,
  defaultValueDimmers,
}) {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">LED requirements</h2>
      <div className="grid gap-12 grid-cols-1 md:grid-cols-2">
        <Quantity
          setValue={setValue}
          register={register}
          label="How many spotlights/downlights do you have?"
          registerItem="spotlights"
          defaultValue={defaultValueSpotlights}
          image={DownlightImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many BC do you have?"
          registerItem="bc"
          defaultValue={defaultValueBc}
          image={BCImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many SES do you have?"
          registerItem="ses"
          defaultValue={defaultValueSes}
          image={SESImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many ES do you have?"
          registerItem="es"
          defaultValue={defaultValueEs}
          image={ESImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many dimmer switches do you have?"
          registerItem="dimmers"
          defaultValue={defaultValueDimmers}
          image={DimmerImage}
        />
      </div>
    </div>
  );
}
