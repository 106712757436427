const appointments = [
  {
    id: 1,
    title: "AM",
    start: new Date(2023, 2, 24, 8, 0),
    end: new Date(2023, 2, 24, 9, 0),
    isAvailable: true,
  },
  {
    id: 2,
    title: "PM",
    start: new Date(2023, 2, 24, 12, 0),
    end: new Date(2023, 2, 24, 1, 0),
    isAvailable: true,
  },
  {
    id: 3,
    title: "AM",
    start: new Date(2023, 2, 27, 12, 0),
    end: new Date(2023, 2, 27, 1, 0),
    isAvailable: true,
  },
  {
    id: 4,
    title: "PM",
    start: new Date(2023, 2, 27, 12, 0),
    end: new Date(2023, 2, 27, 1, 0),
    isAvailable: true,
  },
  {
    id: 5,
    title: "AM",
    start: new Date(2023, 2, 28, 12, 0),
    end: new Date(2023, 2, 28, 1, 0),
    isAvailable: true,
  },
  {
    id: 6,
    title: "PM",
    start: new Date(2023, 2, 28, 12, 0),
    end: new Date(2023, 2, 28, 1, 0),
    isAvailable: true,
  },
  {
    id: 7,
    title: "AM",
    start: new Date(2023, 2, 29, 12, 0),
    end: new Date(2023, 2, 29, 1, 0),
    isAvailable: true,
  },
  {
    id: 8,
    title: "PM",
    start: new Date(2023, 2, 29, 12, 0),
    end: new Date(2023, 2, 29, 1, 0),
    isAvailable: true,
  },
  {
    id: 9,
    title: "AM",
    start: new Date(2023, 2, 30, 12, 0),
    end: new Date(2023, 2, 30, 1, 0),
    isAvailable: true,
  },
  {
    id: 10,
    title: "PM",
    start: new Date(2023, 2, 30, 12, 0),
    end: new Date(2023, 2, 30, 1, 0),
    isAvailable: true,
  },
  {
    id: 11,
    title: "AM",
    start: new Date(2023, 2, 31, 12, 0),
    end: new Date(2023, 2, 31, 1, 0),
    isAvailable: true,
  },
  {
    id: 12,
    title: "PM",
    start: new Date(2023, 2, 31, 12, 0),
    end: new Date(2023, 2, 31, 1, 0),
    isAvailable: true,
  },
  {
    id: 13,
    title: "AM",
    start: new Date(2023, 3, 3, 12, 0),
    end: new Date(2023, 3, 3, 1, 0),
    isAvailable: true,
  },
  {
    id: 14,
    title: "PM",
    start: new Date(2023, 3, 3, 12, 0),
    end: new Date(2023, 3, 3, 1, 0),
    isAvailable: true,
  },
  {
    id: 15,
    title: "AM",
    start: new Date(2023, 3, 4, 12, 0),
    end: new Date(2023, 3, 4, 1, 0),
    isAvailable: true,
  },
  {
    id: 16,
    title: "PM",
    start: new Date(2023, 3, 4, 12, 0),
    end: new Date(2023, 3, 4, 1, 0),
    isAvailable: true,
  },
  {
    id: 17,
    title: "AM",
    start: new Date(2023, 3, 5, 12, 0),
    end: new Date(2023, 3, 5, 1, 0),
    isAvailable: true,
  },
  {
    id: 18,
    title: "PM",
    start: new Date(2023, 3, 5, 12, 0),
    end: new Date(2023, 3, 5, 1, 0),
    isAvailable: true,
  },
  {
    id: 19,
    title: "AM",
    start: new Date(2023, 3, 6, 12, 0),
    end: new Date(2023, 3, 6, 1, 0),
    isAvailable: true,
  },
  {
    id: 20,
    title: "PM",
    start: new Date(2023, 3, 6, 12, 0),
    end: new Date(2023, 3, 6, 1, 0),
    isAvailable: true,
  },
  {
    id: 21,
    title: "AM",
    start: new Date(2023, 3, 7, 12, 0),
    end: new Date(2023, 3, 7, 1, 0),
    isAvailable: true,
  },
  {
    id: 22,
    title: "PM",
    start: new Date(2023, 3, 7, 12, 0),
    end: new Date(2023, 3, 7, 1, 0),
    isAvailable: true,
  },
];

export default appointments;
