import Quantity from "../UI/Quantity";
import YesNo from "../UI/YesNo";
import { useState, useEffect } from "react";

export default function SmokeAlarmRequirements({
  register,
  setValue,
  defaultValueLandings,
  defaultValueSeparateLivingKitchen,
  defaultValueBedRoomsRequired,
  defaultValueBedrooms,
}) {
  const [
    selectedOptionSeparateLivingKitchen,
    setSelectedOptionSeparateLivingKitchen,
  ] = useState(defaultValueSeparateLivingKitchen);
  const [selectedOptionBedRoomsRequired, setSelectedOptionBedRoomsRequired] =
    useState(defaultValueBedRoomsRequired);

  useEffect(() => {
    setValue("separateLivingKitchen", selectedOptionSeparateLivingKitchen);
  }, [selectedOptionSeparateLivingKitchen]);

  useEffect(() => {
    setValue("bedRoomsRequired", selectedOptionBedRoomsRequired);
  }, [selectedOptionBedRoomsRequired]);

  const handleOptionChange = (option, question) => {
    if (question === "separateLivingKitchen") {
      setSelectedOptionSeparateLivingKitchen(option === "yes" ? "yes" : "no");
    } else if (question === "bedRoomsRequired") {
      setSelectedOptionBedRoomsRequired(option === "yes" ? "yes" : "no");
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Smoke Alarm requirements</h2>
      <div className="mb-14">
        <Quantity
          setValue={setValue}
          register={register}
          label="How many halls/landings do you have?"
          registerItem="landings"
          defaultValue={defaultValueLandings}
        />
      </div>
      <h3 className="text-xl font-bold mb-6">
        Do you have a separate living room/kitchen?
      </h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-14">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="separate-yes"
          registerItem="separateLivingKitchen"
          onChange={() => handleOptionChange("yes", "separateLivingKitchen")}
          isSelected={selectedOptionSeparateLivingKitchen === "yes"}
          name="separateLivingKitchen"
        />
        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="separate-no"
          registerItem="separateLivingKitchen"
          onChange={() => handleOptionChange("no", "separateLivingKitchen")}
          isSelected={selectedOptionSeparateLivingKitchen === "no"}
          name="separateLivingKitchen"
        />
      </div>
      <h3 className="text-xl font-bold mb-6">
        Do you need smoke detectors in bedrooms?
      </h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="bedrooms-yes"
          registerItem="bedRoomsRequired"
          onChange={() => handleOptionChange("yes", "bedRoomsRequired")}
          isSelected={selectedOptionBedRoomsRequired === "yes"}
          name="bedRoomsRequired"
        />
        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="bedrooms-no"
          registerItem="bedRoomsRequired"
          onChange={() => handleOptionChange("no", "bedRoomsRequired")}
          isSelected={selectedOptionBedRoomsRequired === "no"}
          name="bedRoomsRequired"
        />
      </div>

      {selectedOptionBedRoomsRequired === "yes" && (
        <div className="mt-14">
          <Quantity
            setValue={setValue}
            register={register}
            label="How many detectors in bedrooms do you need?"
            registerItem="bedrooms"
            defaultValue={defaultValueBedrooms}
          />
        </div>
      )}
    </div>
  );
}
