import React, { useContext } from "react";
import { AppContext } from "../Main";

const ErrorScreen = () => {
  const { errorMessage } = useContext(AppContext);
  return (
    <div>
      <h1>Something went wrong</h1>
      <p>Please try again</p>
    </div>
  );
};

export default ErrorScreen;
