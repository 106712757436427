import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../Main";
import Loader from "../UI/Loader";
import TextInput from "../UI/TextInput";
import {
  CARBON_MONOXIDE,
  CONSUMER_UNIT,
  EICR,
  LED_LIGHTING,
  REWIRING,
  SMOKE_DETECTOR,
  STAIR_LIGHTING,
  getCostCentre,
} from "../../utils/utils";
import TagManager from "react-gtm-module";

export default function ReducedInfoForm() {
  const { register, handleSubmit } = useForm();
  const {
    serviceData,
    setForm,
    setForwardStepTitle,
    setForwardStep,
    formType,
    setBackStep,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    setForwardStepTitle("Email Quote");
    setForwardStep(() => () => {
      const isValid = formRef?.current?.reportValidity();
      if (isValid) {
        formRef?.current?.dispatchEvent(
          new Event("submit", {
            cancelable: true,
            bubbles: true,
            composed: true,
          })
        );
      }
    });
    switch (formType) {
      case REWIRING:
        setBackStep(() => () => setForm(1));
        break;
      case EICR:
        setBackStep(() => () => setForm(2));
        break;
      case CONSUMER_UNIT:
        setBackStep(() => () => setForm(3));
        break;
      case SMOKE_DETECTOR:
        setBackStep(() => () => setForm(4));
        break;
      case CARBON_MONOXIDE:
        setBackStep(() => () => setForm(5));
        break;
      case STAIR_LIGHTING:
        setBackStep(() => () => setForm(6));
        break;
      case LED_LIGHTING:
        setBackStep(() => () => setForm(7));
        break;
      default:
        setBackStep(() => () => setForm(0));
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const prebuilds = serviceData.breakdown.reduce((accumulator, item) => {
        item.prebuildIds.forEach((prebuildId) => {
          const existingItem = accumulator.find((x) => x.ID === prebuildId);

          if (existingItem) {
            existingItem.quantity =
              Number(existingItem.quantity) + Number(item.qty);
          } else {
            accumulator.push({ ID: prebuildId, quantity: item.qty });
          }
        });

        return accumulator;
      }, []);
      const quoteRequest = new Request("/create-quote", {
        method: "POST",
        body: JSON.stringify({
          customerDetails: data,
          prebuilds: prebuilds,
          formName: formType,
          breakdown: serviceData.breakdown,
          globalCostCenterID: getCostCentre(
            formType,
            window.location.href.includes("harkinsandvickers") ? 3 : 2,
            formType === STAIR_LIGHTING &&
              serviceData.data.requirements === "ledConversion"
          ),
          isEmailedQuote: true,
        }),
      });
      await fetch(quoteRequest);
      TagManager.dataLayer({
        dataLayer: {
          event: "quote-created",
        },
      });
      setLoading(false);
      setForm(11);
    } catch (error) {
      setLoading(false);
      console.log(error);
      throw error;
    }
  };
  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="mb-6 text-2xl font-bold lg:mb-12 lg:text-4xl">
        Let's take some details
      </h2>
      {loading && <Loader />}
      {!loading && (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <div className="grid gap-4 grid-cols-1">
            <div>
              <p className=" mb-12 block font-bold text-lg">
                Please fill in your details so we can discuss your job
                requirements further:
              </p>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <TextInput
                  register={register}
                  registerItem="firstName"
                  type="text"
                  label="First name"
                  placeholder="John"
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="lastName"
                  type="text"
                  label="Last name"
                  placeholder="Smith"
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="businessName"
                  type="text"
                  label="Business name"
                  placeholder="Smith Ltd"
                />
                <TextInput
                  register={register}
                  registerItem="email"
                  type="email"
                  label="Email address"
                  placeholder="email@email.com"
                  required={true}
                />
              </div>
              <div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-305 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <label
                  htmlFor="remember"
                  className="ml-2 text-sm font-medium text-black"
                >
                  I agree with the{" "}
                  <a
                    href="https://www.harkinsandvickers.co.uk/terms-conditions/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-black underline"
                  >
                    terms and conditions
                  </a>
                  .
                </label>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
