import Quantity from "../../UI/Quantity";
import singleSocketImage from "../../../images/products/1 Gang Socket.jpg";
import twinSocketImage from "../../../images/products/2 Gang Socket.jpg";
export default function Sockets({
  register,
  setValue,
  defaultValueSingleSocket,
  defaultValueTwinSocket,
}) {
  return (
    <div className="mb-12">
      <h2 className="text-3xl font-bold mb-14">
        How many of the following sockets do you need?
      </h2>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="Single Socket"
          registerItem="singleSocket"
          defaultValue={defaultValueSingleSocket}
          image={singleSocketImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="Twin Socket"
          registerItem="twinSocket"
          defaultValue={defaultValueTwinSocket}
          image={twinSocketImage}
        />
      </div>
    </div>
  );
}
