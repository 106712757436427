import YesNo from "../UI/YesNo";
import { useEffect, useState } from "react";

export default function TestSwitch({
  register,
  setValue,
  defaultValueTestSwitch,
}) {
  const [selectedOptionTestSwitch, setSelectedOptionTestSwitch] = useState(
    defaultValueTestSwitch
  );

  useEffect(() => {
    setValue("testSwitch", selectedOptionTestSwitch);
  }, [selectedOptionTestSwitch]);

  const handleOptionChangeTestSwitch = (option) => {
    setSelectedOptionTestSwitch(option);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Test switch</h2>
      <h3 className="text-xl font-bold mb-6">Do require a test switch?</h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-14">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="test-switch-yes"
          registerItem="testSwitch"
          onChange={() => handleOptionChangeTestSwitch("yes")}
          isSelected={selectedOptionTestSwitch === "yes"}
          name="testSwitch"
        />

        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="test-switch-no"
          registerItem="testSwitch"
          onChange={() => handleOptionChangeTestSwitch("no")}
          isSelected={selectedOptionTestSwitch === "no"}
          name="testSwitch"
        />
      </div>
    </div>
  );
}
