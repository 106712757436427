import "./formParts/Radio.css";
import { useState, createContext, useEffect } from "react";
import Services from "./forms/Services";
import Rewiring from "./forms/Rewiring";
import Eicr from "./forms/Eicr";
import ConsumerUnit from "./forms/ConsumerUnit";
import SmokeDetector from "./forms/SmokeDetector";
import CarbonMonoxide from "./forms/CarbonMonoxide";
import StairLighting from "./forms/StairLighting";
import LedLighting from "./forms/LedLighting";
import InfoForm from "./forms/InfoForm";
import Booking from "./forms/Booking";
import Schedule from "./forms/Schedule";
import Success from "./forms/Success";
import ErrorScreen from "./forms/ErrorScreen";
import FormNav from "./UI/FormNav";
import ReducedInfoForm from "./forms/ReducedInfoForm";

import {
  CARBON_MONOXIDE,
  CONSUMER_UNIT,
  EICR,
  LED_LIGHTING,
  REWIRING,
  SMOKE_DETECTOR,
  STAIR_LIGHTING,
} from "../utils/utils";

export const AppContext = createContext();

export default function Main() {
  // state to manage current form
  const [form, setForm] = useState(0);

  // store choices and inputs from current form path
  const [serviceData, setServiceData] = useState({ isRewiring: false });

  const [customerDetails, setCustomerDetails] = useState({});

  const [images, setImages] = useState([]);

  const [formType, setFormType] = useState("");

  const [schedulingSubheading, setSchedulingSubheading] = useState("");

  const [step, setStep] = useState(0);

  const [formName, setFormName] = useState("");

  const [backStep, setBackStep] = useState();

  const [forwardStep, setForwardStep] = useState();

  const [forwardStepTitle, setForwardStepTitle] = useState("Next");

  const [formDefaultValues, setFormDefaultValues] = useState();

  const [breakdown, setBreakdown] = useState([]);

  const [infoFormReason, setInfoFormReason] = useState("");

  useEffect(() => {
    // Scroll to the top when component mounts or step changes
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, [form, step]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const serviceType = params.get("serviceType");

    if (serviceType) {
      switch (serviceType) {
        case REWIRING:
          setForm(1);
          setFormName("Rewiring");
          window.history.pushState(null, "", "/");
          break;
        case EICR:
          setForm(2);
          setFormName("EICR");
          window.history.pushState(null, "", "/");
          break;
        case CONSUMER_UNIT:
          setForm(3);
          setFormName("Consumer Unit Installation");
          window.history.pushState(null, "", "/");
          break;
        case SMOKE_DETECTOR:
          setForm(4);
          setFormName("Smoke Detector Installation");
          window.history.pushState(null, "", "/");
          break;
        case CARBON_MONOXIDE:
          setForm(5);
          setFormName("Carbon Monoxide Detector Installation");
          window.history.pushState(null, "", "/");
          break;
        case STAIR_LIGHTING:
          setForm(6);
          setFormName("Stair Lighting");
          window.history.pushState(null, "", "/");
          break;
        case LED_LIGHTING:
          setForm(7);
          setFormName("LED Lighting Installation");
          window.history.pushState(null, "", "/");
          break;
        default:
          setForm(0);
          window.history.pushState(null, "", "/");
          break;
      }
    }
  }, []);

  // switch between selected form
  const formSwitcher = () => {
    switch (form) {
      case 0:
        return <Services />;
      case 1:
        return <Rewiring />;
      case 2:
        return <Eicr />;
      case 3:
        return <ConsumerUnit />;
      case 4:
        return <SmokeDetector />;
      case 5:
        return <CarbonMonoxide />;
      case 6:
        return <StairLighting />;
      case 7:
        return <LedLighting />;
      case 8:
        return <InfoForm />;
      case 9:
        return <Booking />;
      case 10:
        return <Schedule />;
      case 11:
        return <Success />;
      case 12:
        return <ErrorScreen />;
      case 13:
        return <ReducedInfoForm />;
      default:
        return <Services />;
    }
  };

  return (
    <AppContext.Provider
      value={{
        form,
        setForm,
        serviceData,
        setServiceData,
        customerDetails,
        setCustomerDetails,
        images,
        setImages,
        formType,
        setFormType,
        schedulingSubheading,
        setSchedulingSubheading,
        step,
        setStep,
        formName,
        setFormName,
        backStep,
        setBackStep,
        forwardStep,
        setForwardStep,
        forwardStepTitle,
        setForwardStepTitle,
        formDefaultValues,
        setFormDefaultValues,
        breakdown,
        setBreakdown,
        infoFormReason,
        setInfoFormReason,
      }}
    >
      <div className="container p-4 lg:p-12 mx-auto min-h-screen">
        {/* pass state and functions to child components via context*/}
        {formSwitcher()}
      </div>
      <div className="spm" style={{display: 'none'}}></div>
      <div className="w-full sticky bottom-0">{form !== 0 && <FormNav />}</div>
    </AppContext.Provider>
  );
}
