import React from "react";

const InfoBox = ({ title, content }) => {
  return (
    <div className="info-box">
      <h2 className="info-box-title">{title}</h2>
      {content}
    </div>
  );
};

export default InfoBox;
