import { useContext } from "react";
import { AppContext } from "../Main";
import EICR from "../../images/fusebox.jpg";
import ConsumerUnit from "../../images/consumer-unit.jpg";
import Stairlighting from "../../images/stairlighting.jpg";
import Smokedetector from "../../images/smokedetector.jpg";
import Ledlighting from "../../images/ledlighting.jpg";
import Carbonmonoxide from "../../images/carbonmonoxide.jpg";
import Rewiring from "../../images/rewiring.jpg";
import ServiceItem from "../UI/ServiceItem";
import BigCal from "../UI/BigCal";
import KgesLogoSquare from "../../images/kges-logo-square.png";
export default function Services() {
  const appContext = useContext(AppContext);

  const services = [
    {
      name: "Rewiring",
      description:
        "Rewire in a safe and timely manner to cause as little disruption to you as possible.",
      image: Rewiring,
      altText: "Plug socket being fitted",
      formNumber: 1,
    },
    {
      name: "EICR",
      description:
        "An EICR will provide you with a full home assessment of your electrical installation and whether it complies with the electrical standards.",
      image: EICR,
      altText: "EICR",
      formNumber: 2,
    },
    {
      name: "Consumer Unit Installation",
      description:
        "The cornerstone of your home’s electrical installation, a new consumer unit provides a solid foundation to any future electrical upgrades.",
      image: ConsumerUnit,
      altText: "Fusebox",
      formNumber: 3,
    },
    {
      name: "Smoke Detector Installation",
      description:
        "Ensure you are adhering to legislation on smoke alarms in your property.",
      image: Smokedetector,
      altText: "Smoke detector",
      formNumber: 4,
    },
    {
      name: "Carbon Monoxide Detector Installation",
      description:
        "Stay compliant with law changes that require all homes to have interlinked alarms in certain rooms.",
      image: Carbonmonoxide,
      altText: "Carbon monoxide detector",
      formNumber: 5,
    },
    {
      name: "Stair Lighting",
      description:
        "The safety and practicality of your premises stairwells starts with a reliable, professional, and skilled electrician.",
      image: Stairlighting,
      altText: "Stair lighting",
      formNumber: 6,
    },
    {
      name: "LED Lighting Installation",
      description:
        "There are huge financial, environmental and practical benefits to switching to LED Lighting.",
      image: Ledlighting,
      altText: "LED lighting",
      formNumber: 7,
    },
    {
      name: "Back to website",
      description: "",
      altText: "Back to website",
      image: KgesLogoSquare,
      href: window.location.href.includes("harkinsandvickers")
        ? "https://www.harkinsandvickers.co.uk"
        : "https://www.keithgunn.co.uk",
    },
  ];

  const updateFormName = (name) => {
    appContext.setFormName(name);
  };

  return (
    <div>
      {/* <BigCal /> */}
      <h2 className="text-3xl font-bold mb-14">
        Which service would you like to book?
      </h2>

      <div className="grid gap-4 grid-cols-1 md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-2">
        {services.map((service, index) => (
          <ServiceItem
            key={index}
            setForm={appContext.setForm}
            setFormName={updateFormName}
            {...service}
          />
        ))}
      </div>
    </div>
  );
}
