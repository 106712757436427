import { useContext, useEffect } from "react";
import { AppContext } from "../Main";
import { REQUESTED_EMAILED_QUOTE, REWIRING } from "../../utils/utils";

export default function Breakdown({ breakdown, watch, formName }) {
  const { setForwardStepTitle, formType, setForm, setInfoFormReason } =
    useContext(AppContext);

  function halfUpRound(num, decimalPlaces = 2) {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      useGrouping: false,
    });

    return formatter.format(num);
  }

  const sum = Object.values(breakdown).reduce(
    (total, item) => total + item.price,
    0
  );

  useEffect(() => {
    if (formType === REWIRING) {
      setForwardStepTitle("Proceed to quote");
    } else {
      setForwardStepTitle("Proceed to booking");
    }
  }, []);

  const bedrooms = watch("bedrooms");
  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <h2 className="mb-6 text-2xl font-bold text-center lg:text-4xl lg:mb-12">
          Your {formName} quote{" "}
          {bedrooms ? `for a ${bedrooms} bedroom home` : null} is:
        </h2>
        <h3 className="mb-6 text-4xl font-bold lg:text-8xl lg:mb-12">
          £{halfUpRound(sum)}
          <span className="text-3xl">+VAT</span>
        </h3>
      </div>
      <div className="mb-12 rounded-lg overflow-hidden max-w-6xl shadow-lg mx-auto">
        {formType === REWIRING && (
          <button
            onClick={() => {
              setForm(13);
              setInfoFormReason(REQUESTED_EMAILED_QUOTE);
            }}
            className="px-4 py-1 rounded-t-md font-medium false bg-yellow-100 w-full hover:bg-green-200"
          >
            Not ready to book right now? Let us email you a quote instead ✉️
          </button>
        )}
        <div className="grid grid-cols-3 gap-1">
          <div className="bg-black text-white py-2 px-4 text-sm font-semibold lg:text-xl">
            <h3>Item</h3>
          </div>
          <div className="bg-black text-white py-2 px-4 text-sm text-center font-semibold lg:text-xl">
            <h3>Quantity</h3>
          </div>
          <div className="bg-black text-white py-2 px-4 text-sm text-right font-semibold lg:text-xl">
            <h3>Total Cost</h3>
          </div>
        </div>
        {breakdown.map((item, index) => {
          return (
            <div className="grid grid-cols-3 gap-1" key={index}>
              <div className="mt-1 text-sm bg-white py-2 px-4 font-semibold lg:text-base">
                <p>{Object.values(item?.name)}</p>
              </div>
              <div className="mt-1 text-sm bg-white py-2 px-4 flex justify-center font-semibold items-center lgtext-base">
                {Object.values(item?.qty.toString())}
              </div>
              <div className="mt-1 text-sm bg-white py-2 px-4 text-right font-semibold items-center lg:text-base">
                <p>£{halfUpRound(item?.price)}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
