export default function YesNo({
  value,
  id,
  registerItem,
  register,
  onChange,
  isSelected,
  ...props
}) {
  return (
    <>
      <input
        className="hidden"
        type="radio"
        value={value}
        id={id}
        name={registerItem}
        {...register(registerItem)}
        onChange={() => onChange(value)}
      />
      <label
        className={`bg-white w-full flex items-center gap-4 shadow-md overflow-hidden rounded-lg hover:ring-2 hover:ring-black ${
          isSelected ? "ring-black bg-black text-white" : "bg-white text-black"
        }`}
        htmlFor={id}
      >
        <div
          className={`border-r-2 border-black p-2 ${
            isSelected ? "bg-black text-white" : ""
          }`}
        >
          {value === "yes" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="h-12 w-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="h-12 w-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>
        <h3 className="capitalize text-2xl font-bold">{value}</h3>
      </label>
    </>
  );
}
