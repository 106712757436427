import { useState, useEffect } from "react";
import QuantityButtons from "./QuantityButtons";

export default function Quantity({
  register,
  setValue,
  defaultValue,
  image,
  ...props
}) {
  const [quantity, setQuantity] = useState(
    defaultValue ? Number(defaultValue) : 0
  );

  const increment = () => {
    setQuantity(quantity + 1);
  };
  const decrement = () => {
    quantity === 0 ? setQuantity(0) : setQuantity(quantity - 1);
  };

  useEffect(() => {
    setValue(props.registerItem, `${quantity}`);
  }, [quantity]);

  return (
    <div className="flex flex-col p-2 items-start gap-6">
      <label htmlFor="large-input" className="text-xl font-bold mb-2">
        {props.label}
      </label>
      {image && (
        <div className="flex justify-center sm:justify-start">
          <img
            src={image}
            className="rounded w-52 aspect-square"
            alt="product"
          />
        </div>
      )}
      {/* <div className="flex justify-center sm:justify-start">
        <div className="flex items-center justify-center overflow-hidden rounded-lg max-w-fit">
          <button
            className="block w-[4rem] p-4 bg-black text-white text-xl font-bold"
            type="button"
            onClick={decrement}
          >
            -
          </button>

          <input
            onWheel={(e) => e.target.blur()}
            type="number"
            name={props.registerItem}
            id="incrementor"
            className="w-[4rem] p-4 text-gray-900 border-y-2 border-black bg-white sm:text-md focus:ring-black"
            {...register(props.registerItem)}
          />
          <button
            className="block w-[4rem] p-4 bg-black text-white text-xl font-bold"
            type="button"
            onClick={increment}
          >
            +
          </button>
        </div>
      </div> */}
      <QuantityButtons
        decrement={decrement}
        increment={increment}
        register={register}
        registerItem={props.registerItem}
      />
    </div>
  );
}
