import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../Main";
import Breakdown from "../formParts/Breakdown";
import IdentifyLighting from "../formParts/IdentifyLighting";
import StairLightingRequirements from "../formParts/StairLightingRequirements";
import Loader from "../UI/Loader";
import prebuildData from "../../data.json";
import { STAIR_LIGHTING, WRONG_LIGHT_FITTING } from "../../utils/utils";

export default function StairLighting() {
  const {
    setForm,
    setFormType,
    setServiceData,
    step,
    setStep,
    setForwardStep,
    setForwardStepTitle,
    setBackStep,
    breakdown,
    setBreakdown,
    formDefaultValues,
    setFormDefaultValues,
    setInfoFormReason,
  } = useContext(AppContext);

  //useState to store endpoint data
  const [simProData, setSimProData] = useState({
    opticalSD: [],
    multiSD: [],
    interlinkedHD: [],
    interlinkedCMD: [],
    RFtestSwitch: [],
  });

  // state to manage loading of data from SimPro
  const [loaded, setLoaded] = useState(false);

  // Hook form to handle EICR form journey data
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValues ? formDefaultValues : {},
  });

  const formRef = useRef();

  useEffect(() => setFormType(STAIR_LIGHTING));

  useEffect(() => {
    if (formDefaultValues) {
      // Form has been submitted so user has navigated back from after form submission
      setStep(2);
      setBackStep(() => () => setStep(1));
      setForwardStep(() => () => setForm(9));
    }
  }, [formDefaultValues]);

  const looksLikeLEDFormValue = watch("looksLike");
  const replaceFormValue = watch("replace");
  const convertFormValue = watch("convert");
  const timeclockFormValue = watch("timeclock");

  useEffect(() => {
    setForwardStepTitle("Next");
    setBackStep(undefined);
    switch (step) {
      case 0:
        if (!looksLikeLEDFormValue) {
          setForwardStep(undefined);
          break;
        }
        if (looksLikeLEDFormValue === "yes") {
          setForwardStep(() => () => setStep(1));
        }
        if (looksLikeLEDFormValue === "no") {
          setInfoFormReason(WRONG_LIGHT_FITTING);
          setForwardStep(() => () => setForm(8));
        }
        break;
      case 1:
        setBackStep(() => () => setStep(0));
        setForwardStepTitle("Get my quote");
        if (
          Boolean(replaceFormValue > 0) ||
          Boolean(convertFormValue > 0) ||
          Boolean(timeclockFormValue > 0)
        ) {
          setForwardStep(
            () => () =>
              formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              )
          );
        } else {
          setForwardStep(undefined);
        }
        break;
      case 2:
        setBackStep(() => () => setStep(1));
        setForwardStep(() => () => setForm(9));
        break;
      default:
        break;
    }
  }, [
    looksLikeLEDFormValue,
    setBackStep,
    setForm,
    setForwardStep,
    setForwardStepTitle,
    setStep,
    step,
    timeclockFormValue,
    replaceFormValue,
    convertFormValue,
  ]);

  // fetch data from SimPro
  useEffect(() => {
    prebuildData.forEach((prebuild) => {
      if (prebuild.ID === 47662) {
        setSimProData((prevState) => ({
          ...prevState,
          replaceAll: prebuild,
        }));
      }
      if (prebuild.ID === 47663) {
        setSimProData((prevState) => ({
          ...prevState,
          pricePerLight: prebuild,
        }));
      }
      if (prebuild.ID === 47664) {
        setSimProData((prevState) => ({
          ...prevState,
          convertToLED: prebuild,
        }));
      }
      if (prebuild.ID === 47665) {
        setSimProData((prevState) => ({
          ...prevState,
          convertOneToLED: prebuild,
        }));
      }
      if (prebuild.ID === 47661) {
        setSimProData((prevState) => ({
          ...prevState,
          installTimeclock: prebuild,
        }));
      }
    });
    setLoaded(true);
  }, []);

  const onSubmit = (data) => {
    function calculateTotals() {
      // array to store breakdown of user choices
      let breakdown = [];

      if (data.replace > 0) {
        breakdown.push({
          name: "Replace lights labour",
          qty: 1,
          price: simProData?.replaceAll.TotalEx,
          time: simProData?.replaceAll.LaborTime,
          prebuildIds: [simProData?.replaceAll.ID],
        });
        breakdown.push({
          name: "Light fitting materials",
          qty: data.replace,
          price: data.replace * simProData?.pricePerLight.TotalEx,
          time: simProData?.pricePerLight?.LaborTime * data.replace,
          prebuildIds: [simProData?.pricePerLight.ID],
        });
      }

      if (data.convert > 0) {
        breakdown.push({
          name: "Convert to LED",
          qty: data.convert,
          price: data.convert * simProData?.convertToLED.TotalEx,
          time: simProData?.convertToLED?.LaborTime * data.convert,
          prebuildIds: [simProData?.convertToLED.ID],
        });
      }
      if (data.timeclock > 0) {
        breakdown.push({
          name: "Timeclock",
          qty: data.timeclock,
          price: data.timeclock * simProData?.installTimeclock.TotalEx,
          time: simProData?.installTimeclock?.LaborTime * data.timeclock,
          prebuildIds: [simProData?.installTimeclock.ID],
        });
      }

      return breakdown;
    }

    setBreakdown(calculateTotals());
    setStep(2);
    setServiceData({
      isRewiring: false,
      data,
      breakdown: calculateTotals(),
    });
    setFormDefaultValues(data);
  };

  //Conditional component to switch between journey steps
  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <IdentifyLighting
            register={register}
            setStep={setStep}
            setValue={setValue}
            defaultValueLooksLike={formDefaultValues?.looksLike}
          />
        );
      case 1:
        return (
          <StairLightingRequirements
            register={register}
            setStep={setStep}
            setValue={setValue}
            defaultValueReplace={formDefaultValues?.replace}
            defaultValueTimeClock={formDefaultValues?.timeclock}
            defaultValueConvert={formDefaultValues?.convert}
          />
        );
      case 2:
        return (
          <Breakdown
            formName={STAIR_LIGHTING}
            breakdown={breakdown}
            watch={watch}
          />
        );
      default:
        return <IdentifyLighting register={register} setStep={setStep} />;
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {/* Component switcher */}
        {loaded ? conditionalComponent() : <Loader />}
      </form>
    </div>
  );
}
