import Main from "./components/Main";
import MobileHeader from "./components/UI/MobileHeader";
import Sidebar from "./components/UI/Sidebar";
import MetaInfo from "./components/content/MetaInfo";

function App() {
  return (
    <>
      <MetaInfo url={"kges.co.uk"} />
      <div className="md:hidden">
        <MobileHeader />
      </div>
      <div className="flex">
        <div className="hidden md:block">
          <Sidebar />
        </div>
        <div className="flex-1">
          <Main />
        </div>
      </div>
    </>
  );
}

export default App;
