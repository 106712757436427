import YesNo from "../UI/YesNo";
import Quantity from "../UI/Quantity";
import { useEffect, useState } from "react";

export default function AlarmAddOn({
  register,
  setValue,
  defaultValueCarbonMonoxide,
  defaultValueFluePassing,
}) {
  const [selectedOption, setSelectedOption] = useState(
    defaultValueCarbonMonoxide
  );

  useEffect(() => {
    if (selectedOption === "no") {
      setValue("fluePassing", 0);
    }
    setValue("carbonMonoxide", selectedOption);
  }, [selectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    if (option === "yes") {
      setValue("carbonMonoxide", "yes");
    } else {
      setValue("carbonMonoxide", "no");
      setValue("fluePassing", 0);
    }
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Carbon monoxide requirements</h2>
      <h3 className="text-xl font-bold mb-6">
        Do you require carbon monoxide detectors?
      </h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-14">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="carbon-monoxide-yes"
          registerItem="carbonMonoxide"
          onChange={() => {
            handleOptionChange("yes");
            setValue("fluePassing", null);
          }}
          isSelected={selectedOption === "yes"}
          name="carbonMonoxide"
        />

        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="carbon-monoxide-no"
          registerItem="carbonMonoxide"
          onChange={() => {
            handleOptionChange("no");
            setValue("carbonMonoxide", "no");
            setValue("fluePassing", 0);
          }}
          isSelected={selectedOption === "no"}
          name="carbonMonoxide"
        />
      </div>
      {selectedOption === "yes" ? (
        <div className="flex my-12">
          <Quantity
            setValue={setValue} // pass setValue here
            register={register}
            label="How many rooms have a gas or fuel burning appliance or a flue passing through?"
            registerItem="fluePassing"
            defaultValue={defaultValueFluePassing}
          />
        </div>
      ) : null}
    </div>
  );
}
