import YesNo from "../UI/YesNo";
import Quantity from "../UI/Quantity";
import { useEffect, useState } from "react";

export default function ApplianceTesting({
  register,
  setValue,
  defaultValueSelectedOptionAppliance,
  defaultValueSelectedOptionFire,
  defaultValueTestingQuantity,
}) {
  const [selectedOptionAppliance, setSelectedOptionAppliance] = useState(
    defaultValueSelectedOptionAppliance
  );

  // watch for fire certificate answer
  const [selectedOptionFire, setSelectedOptionFire] = useState(
    defaultValueSelectedOptionFire
  );

  useEffect(() => {
    setValue("fireCertificate", selectedOptionFire);
  }, [selectedOptionFire]);

  useEffect(() => {
    setValue("applianceTesting", selectedOptionAppliance);
  }, [selectedOptionAppliance]);

  const handleOptionChangeAppliance = (value) => {
    setSelectedOptionAppliance(value);
  };

  const handleOptionChangeFire = (value) => {
    setSelectedOptionFire(value);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Testing & Certificates</h2>
      <h3 className="text-xl font-bold mb-6">
        Do you require Portable Appliance Testing (PAT)?
      </h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-14">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="appliance-yes"
          registerItem="applianceTesting"
          onChange={() => handleOptionChangeAppliance("yes")}
          isSelected={selectedOptionAppliance === "yes"}
          name="applianceTesting"
        />

        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="appliance-no"
          registerItem="applianceTesting"
          onChange={() => handleOptionChangeAppliance("no")}
          isSelected={selectedOptionAppliance === "no"}
          name="applianceTesting"
        />
      </div>
      {selectedOptionAppliance === "yes" ? (
        <div className="mb-14">
          <Quantity
            setValue={setValue}
            register={register}
            label="How many appliances do you need tested?"
            registerItem="testingAmount"
            defaultValue={defaultValueTestingQuantity}
          />
        </div>
      ) : null}

      <h3 className="text-xl font-bold mb-6">
        Do you require a fire alarm certificate?
      </h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="fire-yes"
          onChange={() => handleOptionChangeFire("yes")}
          isSelected={selectedOptionFire === "yes"}
          registerItem="fireCertificate"
          name="fireCertificate"
        />
        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="fire-no"
          onChange={() => handleOptionChangeFire("no")}
          isSelected={selectedOptionFire === "no"}
          registerItem="fireCertificate"
          name="fireCertificate"
        />
      </div>
    </div>
  );
}
