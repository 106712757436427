import { useContext, useEffect, useState } from "react";
import { AppContext } from "../Main";
import YesNo from "../UI/YesNo";
import { EXISTING_ALARMS_SELECTED } from "../../utils/utils";

export default function ExistingAlarms({
  register,
  formName,
  setValue,
  defaultValueExistingAlarms,
}) {
  const [selectedOptionExisting, setSelectedOptionExisting] = useState(
    defaultValueExistingAlarms
  );

  const { setForm, setServiceData, setInfoFormReason } = useContext(AppContext);

  useEffect(() => {
    setValue("existingAlarms", selectedOptionExisting);
  }, [selectedOptionExisting]);

  const handleOptionChange = (option) => {
    setSelectedOptionExisting(option === "yes" ? "yes" : "no");
  };

  console.log(formName);

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">
        Do you have existing{" "}
        {formName === "Carbon Monoxide"
          ? "Carbon Monoxide detectors"
          : "Smoke Alarms"}{" "}
        ?
      </h2>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <YesNo
          register={register}
          value="yes"
          id="existing-yes"
          registerItem="existingAlarms"
          onChange={() => {
            handleOptionChange("yes");
            setServiceData({
              description: `${formName} Alarm Installation - Existing Alarms`,
            });
            setInfoFormReason(EXISTING_ALARMS_SELECTED);
            setForm(8);
          }}
          isSelected={selectedOptionExisting === "yes"}
          name="existingAlarms"
          setValue={setValue} // Add this line
        />

        <YesNo
          register={register}
          value="no"
          id="existing-no"
          registerItem="existingAlarms"
          onChange={() => {
            handleOptionChange("no");
          }}
          isSelected={selectedOptionExisting === "no"}
          name="existingAlarms"
          setValue={setValue} // pass setValue here
        />
      </div>
    </div>
  );
}
