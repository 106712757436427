import { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../Main";
import { useForm } from "react-hook-form";
import ClientInfo from "../formParts/booking/ClientInfo";
import Loader from "../UI/Loader";
import { WebhookSender } from "./WebhookSender";
import {
  CARBON_MONOXIDE,
  CONSUMER_UNIT,
  EICR,
  LED_LIGHTING,
  REWIRING,
  SMOKE_DETECTOR,
  STAIR_LIGHTING,
} from "../../utils/utils";

export default function Booking() {
  const {
    customerDetails,
    setCustomerDetails,
    setForm,
    setForwardStepTitle,
    setForwardStep,
    setBackStep,
    formType,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: customerDetails ? customerDetails : {},
  });

  const formRef = useRef();

  useEffect(() => {
    setForwardStepTitle("Proceed to Scheduling");
    switch (formType) {
      case REWIRING:
        setBackStep(() => () => setForm(1));
        break;
      case EICR:
        setBackStep(() => () => setForm(2));
        break;
      case CONSUMER_UNIT:
        setBackStep(() => () => setForm(3));
        break;
      case SMOKE_DETECTOR:
        setBackStep(() => () => setForm(4));
        break;
      case CARBON_MONOXIDE:
        setBackStep(() => () => setForm(5));
        break;
      case STAIR_LIGHTING:
        setBackStep(() => () => setForm(6));
        break;
      case LED_LIGHTING:
        setBackStep(() => () => setForm(7));
        break;
      default:
        setBackStep(() => () => setForm(0));
    }
  }, []);

  useEffect(() => {
    setForwardStep(() => () => {
      const isValid = formRef?.current?.reportValidity();
      if (isValid) {
        formRef?.current?.dispatchEvent(
          new Event("submit", {
            cancelable: true,
            bubbles: true,
            composed: true,
          })
        );
      }
    });
  }, []);

  const onSubmit = async (data) => {
    setCustomerDetails(data);
    setForm(10);
    WebhookSender({ customerDetails: data });
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="mb-6 text-2xl font-bold lg:mb-12 lg:text-4xl">
        Let's get your appointment booked in
      </h2>
      <div className="grid gap-4 grid-cols-1">
        <div>
          <p className=" mb-12 block font-bold text-lg">
            Please fill in your details so we can schedule your appointment:
          </p>
          <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            {loading && <Loader />}
            {!loading && (
              <>
                <ClientInfo register={register} />
                {/* <button className="py-2 px-4 text-md bg-black text-white rounded-md">
                  {serviceData.isRewiring ? "Book Quote" : "Schedule"}
                </button> */}
              </>
            )}
          </form>
        </div>
        <div></div>
      </div>
    </div>
  );
}
