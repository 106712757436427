import { useContext, useEffect } from "react";
import { AppContext } from "../Main";

export default function FormNav() {
  const {
    setForm,
    setStep,
    formName,
    backStep,
    forwardStep,
    forwardStepTitle,
    setFormDefaultValues,
  } = useContext(AppContext);

  const startAgain = () => {
    setForm(0);
    setStep(0);
    setFormDefaultValues(undefined);
    localStorage.clear();
  };

  useEffect(() => {
    console.log(formName);
  }, [formName]);

  return (
    <div className="py-4 px-4 lg:px-12 bg-white shadow-2xl flex justify-between items-center">
      <button
        className="border-2 border-black px-2 lg:px-4 py-2 rounded-lg font-medium"
        type="button"
        onClick={startAgain}
      >
        Start Again
      </button>

      <p className="font-bold text-sm text-center bg-green-100 py-1 px-4 rounded-md">
        {formName}
      </p>

      <div className="flex gap-2">
        <button
          className={`border-2 border-black px-2 lg:px-4 py-2 rounded-lg font-medium mr-2 ${
            !backStep && "opacity-50 cursor-not-allowed"
          }`}
          type="button"
          onClick={() => backStep()}
          disabled={!backStep}
        >
          Back
        </button>
        <button
          className={`border-2 border-black bg-black text-white px-2 lg:px-4 py-2 rounded-lg font-medium ${
            !forwardStep && "opacity-50 cursor-not-allowed"
          }`}
          type="button"
          onClick={() => forwardStep()}
          disabled={!forwardStep}
        >
          {forwardStepTitle}
        </button>
      </div>
    </div>
  );
}
