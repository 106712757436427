import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../Main";
import Bedrooms from "../formParts/Bedrooms";
import Breakdown from "../formParts/Breakdown";
import Loader from "../UI/Loader";
import prebuildData from "../../data.json";
import { CONSUMER_UNIT, FOUR_BEDROOM_SELECTED } from "../../utils/utils";

export default function ConsumerUnit() {
  const {
    setFormType,
    setServiceData,
    setForm,
    step,
    setStep,
    setForwardStep,
    setBackStep,
    setForwardStepTitle,
    breakdown,
    setBreakdown,
    formDefaultValues,
    setFormDefaultValues,
    setInfoFormReason,
  } = useContext(AppContext);

  //useState to store endpoint data
  const [simProData, setSimProData] = useState({
    baseEICR: [],
    EICRadditional: [],
    _8Way: [],
    _10Way: [],
    _14Way: [],
    baseEIC: [],
    EICadditional: [],
  });

  // state to manage loading of data from SimPro
  const [loaded, setLoaded] = useState(false);

  // React Hook Form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValues ? formDefaultValues : {},
  });

  const formRef = useRef();

  useEffect(() => setFormType(CONSUMER_UNIT));

  useEffect(() => {
    console.log(formDefaultValues);
    if (formDefaultValues) {
      // Form has been submitted so user has navigated back from after form submission
      setStep(1);
      setBackStep(() => () => setStep(0));
      setForwardStep(() => () => setForm(9));
    }
  }, [formDefaultValues]);

  const bedroomsFormValue = watch("bedrooms");

  useEffect(() => {
    switch (step) {
      case 0:
        setForwardStepTitle("Get my quote");
        if (bedroomsFormValue) {
          if (bedroomsFormValue === "4") {
            setForwardStep(() => () => {
              setServiceData({
                description: `4 bedroom property EICR`,
              });
              setInfoFormReason(FOUR_BEDROOM_SELECTED);
              setForm(8);
            });
            return;
          }
          setForwardStep(
            () => () =>
              formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              )
          );
        } else {
          setForwardStep(undefined);
        }
        setBackStep(undefined);
        break;
      case 1:
        setForwardStep(() => () => setForm(9));
        break;
      default:
        setForwardStep(undefined);
        setBackStep(undefined);
    }
  }, [
    step,
    setForwardStep,
    setBackStep,
    setStep,
    setForwardStepTitle,
    bedroomsFormValue,
    setForm,
  ]);

  // fetch data from SimPro
  useEffect(() => {
    prebuildData.forEach((prebuild) => {
      if (prebuild.ID === 47640) {
        setSimProData((prevState) => ({
          ...prevState,
          baseEICR: prebuild,
        }));
      }
      if (prebuild.ID === 47641) {
        setSimProData((prevState) => ({
          ...prevState,
          EICRadditional: prebuild,
        }));
      }
      if (prebuild.ID === 55428) {
        setSimProData((prevState) => ({
          ...prevState,
          _8Way: prebuild,
        }));
      }
      if (prebuild.ID === 55429) {
        setSimProData((prevState) => ({
          ...prevState,
          _10Way: prebuild,
        }));
      }
      if (prebuild.ID === 55430) {
        setSimProData((prevState) => ({
          ...prevState,
          _14Way: prebuild,
        }));
      }
      if (prebuild.ID === 47638) {
        setSimProData((prevState) => ({
          ...prevState,
          baseEIC: prebuild,
        }));
      }
      if (prebuild.ID === 47639) {
        setSimProData((prevState) => ({
          ...prevState,
          EICadditional: prebuild,
        }));
      }
    });
    setLoaded(true);
  }, []);

  const onSubmit = (data) => {
    function calculateTotals() {
      let breakdown = [];

      // Add EIC base price
      breakdown.push({
        name: "EICR base price",
        qty: 1,
        price: simProData?.baseEICR.TotalEx,
        time: simProData?.baseEICR?.LaborTime,
        prebuildIds: [simProData?.baseEICR.ID],
      });

      // Add EIC base price
      breakdown.push({
        name: "EIC base price",
        qty: 1,
        price: simProData?.baseEIC?.TotalEx,
        time: simProData?.baseEIC?.LaborTime,
        prebuildIds: [simProData?.baseEIC?.ID],
      });

      // Check for more than one bedroom and add additional bedroom surcharge
      if (data.bedrooms > 1) {
        breakdown.push({
          name: "EICR additional bedroom surcharge",
          qty: data.bedrooms - 1,
          price: (data.bedrooms - 1) * simProData?.EICRadditional.TotalEx,
          time: simProData?.EICRadditional?.LaborTime * (data.bedrooms - 1),
          prebuildIds: [simProData?.EICRadditional?.ID],
        });
        breakdown.push({
          name: "EIC additional bedroom surcharge",
          qty: data.bedrooms - 1,
          price: (data.bedrooms - 1) * simProData?.EICadditional.TotalEx,
          time: simProData?.EICadditional?.LaborTime * (data.bedrooms - 1),
          prebuildIds: [simProData?.EICadditional?.ID],
        });
      }

      // Check for number of bedrooms and add consumer unit price
      if (data.bedrooms === "1") {
        breakdown.push({
          name: "8 way consumer unit",
          qty: "1",
          price: simProData?._8Way?.TotalEx,
          time: simProData?._8Way?.LaborTime,
          prebuildIds: [simProData?._8Way?.ID],
        });
      }

      // Check for number of bedrooms and add consumer unit price
      if (data.bedrooms === "2") {
        breakdown.push({
          name: "10 way consumer unit",
          qty: "1",
          price: simProData?._10Way?.TotalEx,
          time: simProData?._10Way?.LaborTime,
          prebuildIds: [simProData?._10Way?.ID],
        });
      }

      // Check for number of bedrooms and add consumer unit price
      if (data.bedrooms === "3") {
        breakdown.push({
          name: "14 way consumer unit",
          qty: "1",
          price: simProData?._14Way?.TotalEx,
          time: simProData?._14Way?.LaborTime,
          prebuildIds: [simProData?._14Way?.ID],
        });
      }
      console.log(breakdown);
      return breakdown;
    }

    setBreakdown(calculateTotals());
    setStep(1);
    setServiceData({
      isRewiring: false,
      data,
      breakdown: calculateTotals(),
    });
    setFormDefaultValues(data);
    console.log(breakdown);
  };

  //Conditional component to switch between journey steps
  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <Bedrooms
            register={register}
            setStep={setStep}
            defaultValue={formDefaultValues?.bedrooms}
          />
        );
      case 1:
        return (
          <Breakdown
            formName={CONSUMER_UNIT}
            breakdown={breakdown}
            watch={watch}
          />
        );
      default:
        return (
          <Bedrooms onSubmit={onSubmit} register={register} setStep={setStep} />
        );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {/* Component switcher */}
        {loaded ? conditionalComponent() : <Loader />}
      </form>
    </div>
  );
}
