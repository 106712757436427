import Logo from "../content/Logo";
import Socials from "../content/Socials";

export default function Sidebar() {
  return (
    <aside className="h-screen max-w-[32rem] sticky top-0 bg-black container mx-auto p-12 flex">
      <Logo />
      <Socials />
    </aside>
  );
}
