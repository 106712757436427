import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../Main";
import Breakdown from "../formParts/Breakdown";
import ExistingAlarms from "../formParts/ExistingAlarms";
import SmokeAlarmRequirements from "../formParts/SmokeAlarmRequirements";
import CarbonAlarmAddOn from "../formParts/CarbonAlarmAddOn";
import TestSwitch from "../formParts/TestSwitch";
import Loader from "../UI/Loader";
import prebuildData from "../../data.json";
import { SMOKE_DETECTOR } from "../../utils/utils";

export default function SmokeDetector() {
  const {
    setForm,
    setServiceData,
    setFormType,
    step,
    setStep,
    setForwardStep,
    setBackStep,
    setForwardStepTitle,
    breakdown,
    setBreakdown,
    formDefaultValues,
    setFormDefaultValues,
  } = useContext(AppContext);

  //useState to store endpoint data
  const [simProData, setSimProData] = useState({
    opticalSD: [],
    multiSD: [],
    interlinkedHD: [],
    interlinkedCMD: [],
    RFtestSwitch: [],
  });

  // state to manage loading of data from SimPro
  const [loaded, setLoaded] = useState(false);

  // useForm hook to manage form state
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: formDefaultValues ? formDefaultValues : {},
  });

  const formRef = useRef();

  useEffect(() => setFormType(SMOKE_DETECTOR));

  useEffect(() => {
    if (formDefaultValues) {
      // Form has been submitted so user has navigated back from after form submission
      setStep(4);
      setBackStep(() => () => setStep(3));
      setForwardStep(() => () => setForm(9));
    }
  }, [formDefaultValues]);

  const existingAlarmsFormValue = watch("existingAlarms");
  const separateLivingKitchenFormValue = watch("separateLivingKitchen");
  const bedRoomsRequiredFormValue = watch("bedRoomsRequired");
  const testSwitchFormValue = watch("testSwitch");
  const carbonMonoxideFormValue = watch("carbonMonoxide");

  useEffect(() => {
    setForwardStepTitle("Next");
    switch (step) {
      case 0:
        if (existingAlarmsFormValue) {
          setForwardStep(() => () => setStep(1));
        } else {
          setForwardStep(undefined);
        }
        setBackStep(undefined);
        break;
      case 1:
        setBackStep(() => () => setStep(0));
        if (separateLivingKitchenFormValue && bedRoomsRequiredFormValue) {
          setForwardStep(() => () => setStep(2));
        } else {
          setForwardStep(undefined);
        }
        break;
      case 2:
        setBackStep(() => () => setStep(1));
        if (testSwitchFormValue) {
          setForwardStep(() => () => setStep(3));
        } else {
          setForwardStep(undefined);
        }
        break;
      case 3:
        setBackStep(() => () => setStep(2));
        setForwardStepTitle("Get my quote");
        if (carbonMonoxideFormValue) {
          setForwardStep(
            () => () =>
              formRef.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              )
          );
        } else {
          setForwardStep(undefined);
        }
        break;
      case 4:
        setBackStep(() => () => setStep(3));
        setForwardStep(() => () => {
          setForm(9);
        });
        break;
      default:
        setBackStep(undefined);
        setForwardStep(undefined);
        break;
    }
  }, [
    bedRoomsRequiredFormValue,
    carbonMonoxideFormValue,
    existingAlarmsFormValue,
    separateLivingKitchenFormValue,
    setBackStep,
    setForm,
    setForwardStep,
    setForwardStepTitle,
    setStep,
    step,
    testSwitchFormValue,
  ]);

  // useEffect to fetch data from SimPro
  useEffect(() => {
    prebuildData.forEach((prebuild) => {
      if (prebuild.ID === 47691) {
        setSimProData((prevState) => ({
          ...prevState,
          opticalSD: prebuild,
        }));
      }
      if (prebuild.ID === 55829) {
        setSimProData((prevState) => ({
          ...prevState,
          multiSD: prebuild,
        }));
      }
      if (prebuild.ID === 47692) {
        setSimProData((prevState) => ({
          ...prevState,
          interlinkedHD: prebuild,
        }));
      }
      if (prebuild.ID === 47693) {
        setSimProData((prevState) => ({
          ...prevState,
          interlinkedCMD: prebuild,
        }));
      }
      if (prebuild.ID === 47696) {
        setSimProData((prevState) => ({
          ...prevState,
          RFtestSwitch: prebuild,
        }));
      }
    });
    setLoaded(true);
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    function calculateTotals() {
      //create array to store breakdown
      let breakdown = [];

      if (data.landings > 0) {
        breakdown.push({
          name: "Hall/landing detector",
          qty: data.landings,
          price: simProData?.opticalSD?.TotalEx * data.landings,
          time: simProData?.opticalSD?.LaborTime * data.landings,
          prebuildIds: [simProData?.opticalSD?.ID],
        });
      }

      if (data.separateLivingKitchen === "yes") {
        breakdown.push({
          name: "Living room/kitchen detector",
          qty: 1,
          price:
            simProData?.opticalSD?.TotalEx + simProData?.interlinkedHD?.TotalEx,
          time:
            simProData?.opticalSD?.LaborTime +
            simProData?.interlinkedHD?.LaborTime,
          prebuildIds: [
            simProData?.opticalSD?.ID,
            simProData?.interlinkedHD?.ID,
          ],
        });
      }

      if (data.separateLivingKitchen === "no") {
        breakdown.push({
          name: "Combined living/kitchen detector",
          qty: 1,
          price: simProData?.multiSD?.TotalEx,
          time: simProData?.multiSD?.LaborTime,
          prebuildIds: [simProData?.multiSD?.ID],
        });
      }

      if (data.bedrooms > 0) {
        breakdown.push({
          name: "Bedroom detector",
          qty: data.bedrooms,
          price: simProData?.opticalSD?.TotalEx * data.bedrooms,
          time: simProData?.opticalSD?.LaborTime * data.bedrooms,
          prebuildIds: [simProData?.opticalSD?.ID],
        });
      }

      if (data.testSwitch === "yes") {
        breakdown.push({
          name: "Test switch",
          qty: 1,
          price: simProData?.RFtestSwitch?.TotalEx,
          time: simProData?.RFtestSwitch?.LaborTime,
          prebuildIds: [simProData?.RFtestSwitch?.ID],
        });
      }
      if (data.carbonMonoxide === "yes") {
        breakdown.push({
          name: "Carbon monoxide detector",
          qty: data.fluePassing,
          price: simProData?.interlinkedCMD?.TotalEx * data.fluePassing,
          time: simProData?.interlinkedCMD?.LaborTime * data.fluePassing,
          prebuildIds: [simProData?.interlinkedCMD?.ID],
        });
      }

      return breakdown;
    }

    setBreakdown(calculateTotals());
    setStep(4);
    setServiceData({
      isRewiring: false,
      data,
      breakdown: calculateTotals(),
    });
    setFormDefaultValues(data);
  };

  //Conditional component to switch between journey steps
  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <ExistingAlarms
            register={register}
            setValue={setValue}
            formName={SMOKE_DETECTOR}
            defaultValueExistingAlarms={formDefaultValues?.existingAlarms}
          />
        );
      case 1:
        return (
          <SmokeAlarmRequirements
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValueBedRoomsRequired={formDefaultValues?.bedRoomsRequired}
            defaultValueSeparateLivingKitchen={
              formDefaultValues?.separateLivingKitchen
            }
            defaultValueBedrooms={formDefaultValues?.bedrooms}
            defaultValueLandings={formDefaultValues?.landings}
          />
        );
      case 2:
        return (
          <TestSwitch
            register={register}
            setValue={setValue}
            defaultValueTestSwitch={formDefaultValues?.testSwitch}
          />
        );
      case 3:
        return (
          <CarbonAlarmAddOn
            register={register}
            setValue={setValue}
            setStep={setStep}
            defaultValueCarbonMonoxide={formDefaultValues?.carbonMonoxide}
            defaultValueFluePassing={formDefaultValues?.fluePassing}
          />
        );
      case 4:
        return (
          <Breakdown
            formName={SMOKE_DETECTOR}
            breakdown={breakdown}
            watch={watch}
          />
        );
      default:
        return <ExistingAlarms register={register} setStep={setStep} />;
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {/* Component switcher */}
        {loaded ? conditionalComponent() : <Loader />}
      </form>
    </div>
  );
}
