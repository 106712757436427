export default function PowerItem({
  registerItem,
  register,
  value,
  labelText,
  onChange,
  isSelected,
  image,
  innerComponent,
  ...props
}) {
  return (
    <>
      <input
        className="hidden"
        type="checkbox"
        value={value}
        id={value}
        name={registerItem}
        {...register(registerItem)}
        onChange={() => onChange(value)}
      />
      <label
        className="py-12 px-6 flex flex-col items-center justify-center border border-black border-rounded rounded-xl hover:bg-slate-100"
        htmlFor={value}
      >
        {image && <img src={image} alt="" className="w-52" />}
        {innerComponent && (
          <div
            className="justify-self-center"
            onClick={(e) => e.stopPropagation()}
          >
            {innerComponent()}
          </div>
        )}
        <span className="font-bold text-lg">{labelText}</span>
      </label>
    </>
  );
}
