import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../Main";
import Bedrooms from "../formParts/Bedrooms";
import ApplianceTesting from "../formParts/ApplianceTesting";
import Breakdown from "../formParts/Breakdown";
import Loader from "../UI/Loader";
import prebuildData from "../../data.json";
import { EICR, FOUR_BEDROOM_SELECTED } from "../../utils/utils";

export default function Eicr() {
  const {
    setFormType,
    setServiceData,
    step,
    setStep,
    setForwardStep,
    setBackStep,
    setForwardStepTitle,
    setForm,
    formDefaultValues,
    setFormDefaultValues,
    breakdown,
    setBreakdown,
    setInfoFormReason,
  } = useContext(AppContext);

  const formRef = useRef(null);

  //State for managing which step the form is currently at

  //useState to store endpoint data
  const [simProData, setSimProData] = useState({
    fireCertificate: {},
    baseEICR: {},
    EICRadditional: {},
    pat10: {},
    patAddOn: {},
  });

  // state to provide breakdown of user choices

  // state to manage loading of data from SimPro
  const [loaded, setLoaded] = useState(false);

  // Hook form to handle EICR form journey data
  const { register, setValue, handleSubmit, watch } = useForm({
    defaultValues: formDefaultValues
      ? formDefaultValues
      : {
          testingAmount: 1,
        },
  });

  useEffect(() => {
    console.log("setting form type to EICR");
    setFormType(EICR);
  });

  useEffect(() => {
    if (formDefaultValues) {
      // Form has been submitted so user has navigated back from after form submission
      setStep(2);
      setBackStep(() => () => setStep(1));
      setForwardStep(() => () => setForm(9));
    }
  }, [formDefaultValues]);

  const bedroomsFormValue = watch("bedrooms");
  const applianceTestingFormValue = watch("applianceTesting");
  const testingAmount = watch("testingAmount");
  const fireCertificateFormValue = watch("fireCertificate");

  useEffect(() => {
    const applianceTestingFormIsValid = () => {
      if (applianceTestingFormValue === "yes") {
        if (testingAmount > 0 && fireCertificateFormValue) return true;
        return false;
      } else if (
        applianceTestingFormValue === "no" &&
        fireCertificateFormValue
      ) {
        return true;
      }
      return false;
    };
    // form navigation
    if (step === 0) {
      setBackStep(undefined);
      if (bedroomsFormValue === "4") {
        setForwardStep(() => () => {
          setServiceData({
            description: `4 bedroom property EICR`,
          });
          setInfoFormReason(FOUR_BEDROOM_SELECTED);
          setForm(8);
        });
        return;
      }
      if (bedroomsFormValue > 0) {
        setForwardStep(() => () => setStep(1));
      } else {
        setForwardStep(undefined);
      }
    }
    if (step === 1) {
      setBackStep(() => () => setStep(0));
      setForwardStepTitle("Get my quote");
      if (applianceTestingFormIsValid()) {
        setForwardStep(() => () => {
          formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
          setStep(2);
        });
      } else {
        setForwardStep(undefined);
      }
    }
    if (step === 2) {
      setBackStep(() => () => setStep(1));
    } else {
      setForwardStepTitle("Next");
    }
  }, [
    step,
    setStep,
    setForwardStep,
    setBackStep,
    bedroomsFormValue,
    applianceTestingFormValue,
    fireCertificateFormValue,
    testingAmount,
    setForwardStepTitle,
    setServiceData,
    setForm,
  ]);

  // fetch data from SimPro
  useEffect(() => {
    prebuildData.forEach((prebuild) => {
      if (prebuild.ID === 50099) {
        setSimProData((prevState) => ({
          ...prevState,
          fireCertificate: prebuild,
        }));
      }
      if (prebuild.ID === 47611) {
        setSimProData((prevState) => ({
          ...prevState,
          baseEICR: prebuild,
        }));
      }
      if (prebuild.ID === 47612) {
        setSimProData((prevState) => ({
          ...prevState,
          EICRadditional: prebuild,
        }));
      }
      if (prebuild.ID === 50097) {
        setSimProData((prevState) => ({
          ...prevState,
          pat10: prebuild,
        }));
      }
      if (prebuild.ID === 50096) {
        setSimProData((prevState) => ({
          ...prevState,
          patAddOn: prebuild,
        }));
      }
    });
    setLoaded(true);
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    function calculateTotals() {
      let breakdown = [];

      // Check for base price only
      breakdown.push({
        name: "EICR base cost",
        qty: 1,
        price: simProData?.baseEICR.TotalEx,
        time: simProData?.baseEICR?.LaborTime,
        prebuildIds: [simProData?.baseEICR.ID],
      });

      // check for bedrooms amount and add additional bedroom surcharge
      if (data.bedrooms > 1) {
        breakdown.push({
          name: "EICR additional bedroom surcharge",
          qty: data.bedrooms - 1,
          price: (data.bedrooms - 1) * simProData?.EICRadditional.TotalEx,
          time: simProData?.EICRadditional?.LaborTime * (data.bedrooms - 1),
          prebuildIds: [simProData?.EICRadditional.ID],
        });
      }

      // Check for PAT testing base only
      if (data.applianceTesting === "yes") {
        breakdown.push({
          name: "PAT testing up to 10 bedrooms",
          qty: 1,
          price: simProData?.pat10.TotalEx,
          time: simProData?.pat10?.LaborTime,
          prebuildIds: [simProData?.pat10.ID],
        });
      }

      // Check for PAT testing items over 10
      if (data.testingAmount > 10) {
        breakdown.push({
          name: "PAT testing add on item",
          qty: parseInt(data.testingAmount, 10) - 10,
          price: (data.testingAmount - 10) * simProData?.patAddOn.TotalEx,
          time: simProData?.patAddOn?.LaborTime * (data.testingAmount - 10),
          prebuildIds: [simProData?.patAddOn.ID],
        });
      }

      // Check for fire certificate required
      if (data.fireCertificate === "yes") {
        breakdown.push({
          name: "Fire Certificate",
          qty: 1,
          price: simProData?.fireCertificate.TotalEx,
          time: simProData?.fireCertificate?.LaborTime,
          prebuildIds: [simProData?.fireCertificate.ID],
        });
      }

      return breakdown;
    }

    setBreakdown(calculateTotals());
    setForwardStep(() => () => {
      setForm(9);
    });
    setServiceData({
      isRewiring: false,
      data,
      breakdown: calculateTotals(),
    });
    setFormDefaultValues(data);
  };

  //Conditional component to switch between journey steps
  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <Bedrooms
            register={register}
            setStep={setStep}
            defaultValue={formDefaultValues?.bedrooms}
          />
        );
      case 1:
        return (
          <ApplianceTesting
            setValue={setValue}
            watch={watch}
            register={register}
            defaultValueSelectedOptionAppliance={
              formDefaultValues?.applianceTesting
            }
            defaultValueSelectedOptionFire={formDefaultValues?.fireCertificate}
            defaultValueTestingQuantity={formDefaultValues?.testingAmount}
          />
        );
      case 2:
        return (
          <Breakdown formName={EICR} breakdown={breakdown} watch={watch} />
        );
      default:
        return <Bedrooms register={register} setStep={setStep} />;
    }
  };

  return (
    <div>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} method="POST">
        {/* Component switcher */}
        {loaded ? conditionalComponent() : <Loader />}
      </form>
    </div>
  );
}
