import Quantity from "../UI/Quantity";

export default function CarbonMonoxideRequirements({
  register,
  setValue,
  defaultValueFluePassing,
}) {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Carbon monoxide requirements</h2>
      <div className="flex mb-24">
        <Quantity
          setValue={setValue}
          register={register}
          label="How many rooms have a gas or fuel burning appliance or a flue
            passing through?"
          registerItem="fluePassing"
          defaultValue={defaultValueFluePassing}
        />
      </div>
    </div>
  );
}
