import React from "react";

const Card = ({ title, content, type, downloadPdf }) => {
  if (type !== "success") {
    return (
      <div className="max-w-sm rounded overflow-hidden shadow-lg">
        <div className="px-6 py-4 text-center">
          <div className="font-bold text-xl mb-2">{title}</div>
          <p className="text-gray-700 text-base">{content}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="relative max-w-2xl bg-white rounded-md shadow-xl">
      <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 flex justify-center bg-gray-100 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-12 h-12 text-green-300"
        >
          <path
            fill-rule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div className="p-8 rounded-t-md">
        <h1 className="text-2xl text-center font-bold mt-4 mb-10">{title}</h1>
        {content}
      </div>
      {downloadPdf && (
        <div className="bg-yellow-100 px-8 py-2 rounded-b-md">
          <a
            className="flex items-center gap-2"
            href={downloadPdf}
            download={downloadPdf.substring(downloadPdf.lastIndexOf("/") + 1)}
            onClick={downloadPdf}
          >
            Download help guide
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-5 h-5"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                clip-rule="evenodd"
              />
            </svg>
          </a>
        </div>
      )}
    </div>
  );
};

export default Card;
