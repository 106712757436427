import KGESlogo from "../../images/kgeslogo.png";
import HarkinsAndVickersLogo from "../../images/harkinsandvickerslogo.svg";

const Logo = () => {
  if (window.location.href.includes("harkinsandvickers")) {
    return (
      <img
        src={HarkinsAndVickersLogo}
        alt="Harkins and Vickers"
        className="h-10 cursor-pointer"
        onClick={() =>
          (window.location = "https://www.harkinsandvickers.co.uk")
        }
      />
    );
  } else {
    return (
      <img
        src={KGESlogo}
        alt="KG Electrical Services"
        className="h-10 cursor-pointer"
        onClick={() => (window.location = "https://www.keithgunn.co.uk")}
      />
    );
  }
};

export default Logo;
