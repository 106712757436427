import PowerItem from "../../UI/PowerItem";
import electricOvenImage from "../../../images/products/Oven Switch.jpg";
import immersionTankImage from "../../../images/products/Immersion Switch.jpg";
import electricShowerImage from "../../../images/products/Shower Switch.jpg";
import electricBoilerImage from "../../../images/products/Boiler Switch.jpg";
import electricHobImage from "../../../images/products/Hob Switch.jpg";
import alarmImage from "../../../images/products/Intruder Alarm Spur.jpg";
import applianceImage from "../../../images/products/Kitchen Appliance Spur.jpg";
import shaverSocketImage from "../../../images/products/Shaver Socket.jpg";
import Quantity from "../../UI/Quantity";
import QuantityButtons from "../../UI/QuantityButtons";
import { useForm } from "react-hook-form";

export default function Power({
  register,
  setValue,
  powerFormValue,
  applianceSwitchQuantity,
}) {
  const onChange = (value) => {
    const isChecked = powerFormValue.includes(value);
    const newPowerFormValue = isChecked
      ? powerFormValue.filter((item) => item !== value)
      : powerFormValue.concat(value);
    setValue("power", newPowerFormValue);
  };
  console.log(applianceSwitchQuantity);

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">
        Which of the following power appliances do you have?
      </h2>
      <h3 className="text-3xl font-bold">Kitchen</h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 mt-12">
        <PowerItem
          register={register}
          value="electric-oven"
          registerItem="power"
          labelText="Electric Oven"
          onChange={onChange}
          image={electricOvenImage}
        />
        <PowerItem
          register={register}
          value="electric-hob"
          registerItem="power"
          labelText="Electric hob"
          onChange={onChange}
          image={electricHobImage}
        />
        <PowerItem
          register={register}
          value="applianceSwitch"
          registerItem="power"
          labelText="Fused kitchen appliance switch"
          onChange={onChange}
          image={applianceImage}
          innerComponent={() =>
            powerFormValue.includes("applianceSwitch") ? (
              <QuantityButtons
                decrement={() => {
                  if (applianceSwitchQuantity === 1) {
                    setValue(
                      "power",
                      powerFormValue.filter(
                        (value) => value !== "applianceSwitch"
                      )
                    );
                    return;
                  }
                  setValue(
                    "applianceSwitchQuantity",
                    Number(applianceSwitchQuantity) - 1
                  );
                }}
                increment={() => {
                  setValue(
                    "applianceSwitchQuantity",
                    Number(applianceSwitchQuantity) + 1
                  );
                }}
                registerItem="applianceSwitchQuantity"
                register={register}
              />
            ) : undefined
          }
        />
        {/* {powerFormValue.includes("applianceSwitch") && (
          <QuantityButtons
            decrement={() => {
              if (applianceSwitchQuantity === 1) {
                setValue(
                  "power",
                  powerFormValue.filter((value) => value !== "applianceSwitch")
                );
                return;
              }
              setValue(
                "applianceSwitchQuantity",
                Number(applianceSwitchQuantity) - 1
              );
            }}
            increment={() => {
              setValue(
                "applianceSwitchQuantity",
                Number(applianceSwitchQuantity) + 1
              );
            }}
            registerItem="applianceSwitchQuantity"
            register={register}
          />
        )} */}
      </div>
      <h3 className="text-3xl font-bold mt-14">Bathroom</h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 mt-12">
        <PowerItem
          register={register}
          value="shaverSocket"
          registerItem="power"
          labelText="Shaver socket"
          onChange={onChange}
          image={shaverSocketImage}
        />
        <PowerItem
          register={register}
          value="electric-shower"
          registerItem="power"
          labelText="Electric Shower"
          onChange={onChange}
          image={electricShowerImage}
        />
      </div>
      <h3 className="text-3xl font-bold mt-14">Misc</h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 mt-12">
        <PowerItem
          register={register}
          value="intruder-alarm"
          registerItem="power"
          labelText="Intruder alarm"
          onChange={onChange}
          image={alarmImage}
        />
        <PowerItem
          register={register}
          value="immersion-tank"
          registerItem="power"
          labelText="Immersion Tank"
          onChange={onChange}
          image={immersionTankImage}
        />
        <PowerItem
          register={register}
          value="fused-boiler-switch"
          registerItem="power"
          labelText="Electric Boiler"
          onChange={onChange}
          image={electricBoilerImage}
        />
      </div>
    </div>
  );
}
