export const WebhookSender = async ({ customerDetails }) => {
  // Get the full URL of the current page
  const sourceUrl = window.location.href;

  // Append the sourceUrl to the customerDetails object
  const fullDetails = {
    ...customerDetails,
    sourceUrl, // Include the source URL in the data sent to the webhook
  };

  // Create query parameters from the full details including the source URL
  const queryParams = new URLSearchParams(fullDetails).toString();
  const webhookUrl = `https://hook.eu2.make.com/b9bdmqunrlj4rmu56zxrm0wmdiqadskq?${queryParams}`;

  try {
    const response = await fetch(webhookUrl); // Default method is GET
    const jsonResponse = await response.json();
    console.log("Webhook response:", jsonResponse);
  } catch (error) {
    console.error("Failed to send data to webhook:", error);
  }
};
