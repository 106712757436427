export default function BedroomItem({ isSelected, register, ...props }) {
  return (
    <>
      <input
        onClick={(e) => props.handleClick(e.target.value)}
        className="hidden"
        type="radio"
        value={props.value}
        id={props.id}
        {...register(props.registerItem)}
      />
      <label
        className={`bg-white w-full flex items-center gap-4 shadow-md overflow-hidden rounded-lg hover:ring-2 hover:ring-black ${
          isSelected ? "ring-black bg-black text-white" : "bg-white text-black"
        }`}
        htmlFor={props.id}
      >
        <div className={`border-r-2 border-black text-center`}>
          {" "}
          <h2
            className={`py-2 px-4 min-w-[4rem] flex justify-center text-2xl font-bold lg:text-5xl ${
              isSelected ? "bg-black text-white" : ""
            }`}
          >
            {props.value < 4 ? (
              props.value
            ) : (
              <span>
                4<span className="lg:text-3xl">+</span>
              </span>
            )}
          </h2>
        </div>
        <h3 className="capitalize text-2xl font-bold">
          {props.value > 1 ? "Bedrooms" : "Bedroom"}
        </h3>
      </label>
    </>
  );
}
