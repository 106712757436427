const QuantityButtons = ({
  decrement,
  increment,
  register,
  registerItem,
  ...props
}) => (
  <div className="flex justify-center sm:justify-start sm:w-full">
    <div className="flex items-center justify-center overflow-hidden rounded-lg max-w-fit">
      <button
        className="block w-[4rem] p-4 bg-black text-white text-xl font-bold"
        type="button"
        onClick={decrement}
      >
        -
      </button>

      <input
        onWheel={(e) => e.target.blur()}
        type="number"
        name={registerItem}
        id="incrementor"
        className="w-[4rem] p-4 text-gray-900 border-y-2 border-black bg-white sm:text-md focus:ring-black"
        {...register(registerItem)}
      />
      <button
        className="block w-[4rem] p-4 bg-black text-white text-xl font-bold"
        type="button"
        onClick={increment}
      >
        +
      </button>
    </div>
  </div>
);

export default QuantityButtons;
