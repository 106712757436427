import React from "react";
import Logo from "../content/Logo";

const MobileHeader = () => {
  return (
    <div className="flex items-center justify-center bg-black p-5">
      <Logo />
    </div>
  );
};

export default MobileHeader;
