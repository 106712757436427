import { useState, useRef } from "react";

export default function ServiceItem({ setForm, setFormName, ...props }) {
  const [hover, setHover] = useState(false);

  const infoRef = useRef(null);
  const infoButtonRef = useRef(null);

  const handleInfoClick = () => {
    if (infoRef.current.classList.contains("hidden")) {
      infoRef.current.classList.remove("hidden");
    } else {
      infoRef.current.classList.add("hidden");
    }
  };

  const handleClick = (e) => {
    if (props.href) {
      // this is a link back to the marketing website
      e.preventDefault();
      console.log(props.href);
      window.location.href = props.href;
      return;
    }
    setForm(props.formNumber);
    setFormName(props.name);
  };

  return (
    <>
      <div className="w-full flex shadow-md overflow-hidden rounded-lg hover:ring-2 hover:ring-black md:min-h-[8rem]">
        <button
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={handleClick}
          value={props.name}
          type="button"
          className="flex flex-1 items-center bg-white"
        >
          <div className="flex-none w-16 h-full md:w-32 lg:w-36 border-r-2 border-black">
            <img
              className={
                "h-full w-full object-cover" +
                (props?.href ? " bg-black p-3" : "")
              }
              src={props.image}
              alt={props.altText}
            />
          </div>
          <div className="w-full h-full flex flex-col justify-between">
            <div className="flex-1 flex flex-col justify-between md:justify-center items-start p-4 md:p-8">
              <h2 className="text-m text-start md:text-2xl lg:text-2xl">
                {props.name}
              </h2>
              <p className="hidden text-start md:mt-4 lg:block">
                {props.description}
              </p>
            </div>
          </div>
        </button>
        <button
          type="button"
          ref={infoButtonRef}
          onClick={handleInfoClick}
          className={
            "lg:hidden bg-black flex items-center justify-center px-2" +
            (props.href ? " hidden" : "")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </button>
      </div>
      <p ref={infoRef} className="hidden text-start md:mt-4">
        {props.description}
      </p>
    </>
  );
}
