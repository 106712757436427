import Quantity from "../../UI/Quantity";
import SmokeDetectorImage from "../../../images/products/Optical Detector.jpg";
import HeatDetectorImage from "../../../images/products/Heat Detector.jpg";
import CarbonMonoxideDetectorImage from "../../../images/products/Carbon Monoxide Detector.png";
export default function SmokeCarbon({
  register,
  setValue,
  defaultValueSmokeDetector,
  defaultValueCarbonDetector,
  defaultValueHeatDetector,
}) {
  return (
    <div className="mb-12">
      <h2 className="text-3xl font-bold mb-14">
        How many smoke and carbon dioxide detectors do you have?
      </h2>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="Smoke Detectors"
          registerItem="smokeDetector"
          defaultValue={defaultValueSmokeDetector}
          image={SmokeDetectorImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="Heat Detectors"
          registerItem="heatDetector"
          defaultValue={defaultValueHeatDetector}
          image={HeatDetectorImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="Carbon Monoxide Detectors"
          registerItem="carbonDetector"
          defaultValue={defaultValueCarbonDetector}
          image={CarbonMonoxideDetectorImage}
        />
      </div>
    </div>
  );
}
