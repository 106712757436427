import Quantity from "../UI/Quantity";

export default function StairLightingRequirement({
  register,
  setValue,
  defaultValueReplace,
  defaultValueConvert,
  defaultValueTimeclock,
}) {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Stair lighting requirements</h2>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <Quantity
          setValue={setValue}
          register={register}
          label="How many light bulbs would you like us to replace?"
          registerItem="replace"
          defaultValue={defaultValueReplace}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many would you like us to convert to LED?"
          registerItem="convert"
          defaultValue={defaultValueConvert}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many time clocks would you like fitted?"
          registerItem="timeclock"
          defaultValue={defaultValueTimeclock}
        />
      </div>
    </div>
  );
}
