export const EICR = "EICR";
export const CONSUMER_UNIT = "Consumer Unit";
export const CARBON_MONOXIDE = "Carbon Monoxide";
export const SMOKE_DETECTOR = "Smoke Detector";
export const STAIR_LIGHTING = "Stair Lighting";
export const REWIRING = "Rewiring";
export const LED_LIGHTING = "LED Lighting";

export const REQUESTED_EMAILED_QUOTE = "REQUESTED_EMAILED_QUOTE";
export const FOUR_BEDROOM_SELECTED = "FOUR_BEDROOM_SELECTED";
export const WRONG_LIGHT_FITTING = "WRONG_LIGHT_FITTING";
export const EXISTING_ALARMS_SELECTED = "EXISTING_ALARMS_SELECTED";
export const COULD_NOT_FIND_TIMESLOT = "COULD_NOT_FIND_TIMESLOT";

export const getCostCentre = (
  formType,
  companyID,
  isStairLightingLedConversion
) => {
  if (companyID === 2) {
    // Is Keith Gunn
    switch (formType) {
      case EICR:
        return 76;
      case REWIRING:
        return 82;
      case CONSUMER_UNIT:
        return 77;
      case LED_LIGHTING:
        return 81;
      case STAIR_LIGHTING:
        if (isStairLightingLedConversion) return 79;
        // Is not LED
        return 78;
      case SMOKE_DETECTOR:
        return 80;
      default:
        return 28;
    }
  } else {
    // Is Harkins and Vickers
    switch (formType) {
      case EICR:
        return 83;
      case REWIRING:
        return 86;
      case CONSUMER_UNIT:
        return 84;
      case LED_LIGHTING:
        return 85;
      case STAIR_LIGHTING:
        if (isStairLightingLedConversion) return 88;
        // Is not LED
        return 89;
      case SMOKE_DETECTOR:
        return 87;
      default:
        return 67;
    }
  }
};
