import { useContext, useEffect, useState } from "react";
import { AppContext } from "../Main";
import YesNo from "../UI/YesNo";
import KeithGunnLightFittingImage from "../../images/keith-gunn-light-fitting.png";
import HarkinsAndVickersLightFittingImage from "../../images/harkins-and-vickers-light-fitting.png";

export default function IdentifyLighting({
  register,
  setValue,
  defaultValueLooksLike,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValueLooksLike);

  useEffect(() => {
    setValue("looksLike", selectedOption);
  }, [selectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Identify your lighting</h2>
      <h3 className="text-xl font-bold mb-12">
        Does your light fitting look like this?
      </h3>
      <img
        className="h-52 md:h-56 rounded mb-12"
        src={
          window.location.href.includes("harkinsandvickers")
            ? HarkinsAndVickersLightFittingImage
            : KeithGunnLightFittingImage
        }
        alt="light fitting"
      />
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="looks-like-yes"
          registerItem="looksLike"
          onChange={() => {
            handleOptionChange("yes");
          }}
          isSelected={selectedOption === "yes"}
        />
        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="looks-like-no"
          registerItem="looksLike"
          onChange={() => {
            handleOptionChange("no");
          }}
          isSelected={selectedOption === "no"}
        />
      </div>
    </div>
  );
}
