import SmokeAlarmRequirements from "./SmokeAlarmRequirements";
import YesNo from "../UI/YesNo";
import { useEffect, useState } from "react";

export default function SmokeAlarmAddOn({
  register,
  watch,
  setValue,
  defaultValueSmokeAlarm,
  defaultValueBedRoomsRequired,
  defaultValueBedrooms,
  defaultValueLandings,
  defaultValueSeparateLivingKitchen,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValueSmokeAlarm);

  useEffect(() => {
    setValue("smokeAlarm", selectedOption);
  }, [selectedOption]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">Smoke alarms</h2>
      <h3 className="text-xl font-bold mb-12">Do you need smoke alarms?</h3>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 mb-14">
        <YesNo
          register={register}
          setValue={setValue}
          value="yes"
          id="smoke-alarm-yes"
          registerItem="smokeAlarm"
          onChange={() => handleOptionChange("yes")}
          isSelected={selectedOption === "yes"}
          name="smokeAlarm"
        />

        <YesNo
          register={register}
          setValue={setValue}
          value="no"
          id="smoke-alarm-no"
          registerItem="smokeAlarm"
          onChange={() => handleOptionChange("no")}
          isSelected={selectedOption === "no"}
          name="smokeAlarm"
        />
      </div>

      {selectedOption === "yes" ? (
        <SmokeAlarmRequirements
          register={register}
          setValue={setValue}
          watch={watch}
          defaultValueBedRoomsRequired={defaultValueBedRoomsRequired}
          defaultValueBedrooms={defaultValueBedrooms}
          defaultValueLandings={defaultValueLandings}
          defaultValueSeparateLivingKitchen={defaultValueSeparateLivingKitchen}
        />
      ) : null}
    </div>
  );
}
