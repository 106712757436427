import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styles from "./BigCal.module.css";
import appointments from "../../appointments";

const localizer = momentLocalizer(moment);

const BigCal = () => {
  const [events, setEvents] = useState(appointments);

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "#ffffff";
    let borderColor = "#000000";
    let color = "#000000";
    let className = "";

    if (!event.isAvailable) {
      backgroundColor = "#ccc";
      borderColor = "#ccc";
    } else {
      className = styles["green-dot"];
    }

    if (isSelected) {
      backgroundColor = "#000000";
      color = "#ffffff";
    }

    return {
      className: className,
      style: {
        backgroundColor,
        borderColor,
        color,
      },
    };
  };

  const weekStart = new Date();
  weekStart.setHours(8, 0, 0, 0);
  const weekEnd = new Date();
  weekEnd.setHours(19, 0, 0, 0);

  return (
    <div className={styles["calendar-container"]}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}
        views={{ month: true, week: true, day: true }}
        defaultView="month"
        min={weekStart}
        max={weekEnd}
        className="calander-component"
      />
    </div>
  );
};

export default BigCal;
