import { useState } from "react";
import BedroomItem from "../UI/BedroomItem";

export default function Bedrooms({ register, defaultValue }) {
  const [bedrooms, setBedrooms] = useState(defaultValue);

  const bedroomOptions = [
    {
      value: "1",
      id: "1-bedroom",
      registerItem: "bedrooms",
      handleClick: setBedrooms,
    },
    {
      value: "2",
      id: "2-bedroom",
      registerItem: "bedrooms",
      handleClick: setBedrooms,
    },
    {
      value: "3",
      id: "3-bedroom",
      registerItem: "bedrooms",
      handleClick: setBedrooms,
    },
    {
      value: "4",
      id: "4-bedroom",
      registerItem: "bedrooms",
      handleClick: setBedrooms,
    },
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">
        How many bedrooms does your property have?
      </h2>
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {bedroomOptions.map((option, index) => {
          return (
            <BedroomItem
              key={index}
              register={register}
              value={option.value}
              id={option.id}
              registerItem={option.registerItem}
              handleClick={option.handleClick}
              isSelected={bedrooms === option.value}
            />
          );
        })}
      </div>
    </div>
  );
}
