import Quantity from "../../UI/Quantity";
import BathroomFanImage from "../../../images/products/Standard Bathroom Fan.jpg";
import TimerFanImage from "../../../images/products/Timer Bathroom Fan.jpg";
export default function Fans({
  register,
  setValue,
  defaultValue4InchStandardFan,
  defaultValue4InchTimerFan,
}) {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">
        What type of fans do you have?
      </h2>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="How many bathroom fans do you have?"
          registerItem="4InchStandardFan"
          defaultValue={defaultValue4InchStandardFan}
          image={BathroomFanImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many timer bathroom fans do you have?"
          registerItem="4InchTimerFan"
          defaultValue={defaultValue4InchTimerFan}
          image={TimerFanImage}
        />
      </div>
    </div>
  );
}
