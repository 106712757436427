import Quantity from "../../UI/Quantity";
import OneGangLightSwitchImage from "../../../images/products/1 Gang Light Switch.jpg";
import TwoGangLightSwitchImage from "../../../images/products/2 Gang Light Switch.jpg";
import ThreeGangLightSwitchImage from "../../../images/products/3 Gang Light Switch.jpg";
import FourGangLightSwitchImage from "../../../images/products/4 Gang Light Switch.jpg";
import OneGangDimmerSwitchImage from "../../../images/products/1 Gang Dimmer Switch.jpg";
import TwoGangDimmerSwitchImage from "../../../images/products/2 Gang Dimmer Switch.jpg";
import ThreeGangDimmerSwitchImage from "../../../images/products/3 Gang Dimmer Switch.jpg";
import FourGangDimmerSwitchImage from "../../../images/products/4 Gang Dimmer Switch.jpg";
import LedBathroomLightImage from "../../../images/products/Bathroom Rated Light Fitting.jpg";
import SatinChromeBathroomDownlightImage from "../../../images/products/Satin Chrome Bathroom Downlight.png";
import WhiteBathroomDownlightImage from "../../../images/products/White Bathroom Downlight.png";
import SixInchPendantLightFittingImage from "../../../images/products/Pendant Light Fitting.jpg";
import SatinChromeDownlightFittingImage from "../../../images/products/Satin Chrome Downlight.png";
import WhiteDownlightFittingImage from "../../../images/products/White Downlight.png";
export default function Lighting({
  register,
  setValue,
  defaultValueOneGangLightSwitch,
  defaultValueTwoGangLightSwitch,
  defaultValueThreeGangLightSwitch,
  defaultValueFourGangLightSwitch,
  defaultValueOneGangDimmerSwitch,
  defaultValueTwoGangDimmerSwitch,
  defaultValueThreeGangDimmerSwitch,
  defaultValueFourGangDimmerSwitch,
  defaultValueSatinChromeBathroomDownlight,
  defaultValueWhiteBathroomDownlight,
  defaultValueLedBathroomLight,
  // defaultValueCustomerSuppliedLightFittingBathroom,
  // defaultValueCustomerSuppliedLightFitting,
  defaultValueSixInchPendantLightFitting,
  defaultValueSatinChromeDownlight,
  defaultValueWhiteDownlight,
}) {
  return (
    <div className="mb-12">
      <h2 className="text-3xl font-bold mb-14">
        How many of the following lighting options do you need?
      </h2>
      <h3 className="text-3xl font-bold">Switches</h3>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="1 gang light switch"
          registerItem="OneGangTwoWaySwitch"
          defaultValue={defaultValueOneGangLightSwitch}
          image={OneGangLightSwitchImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="2 gang light switch"
          registerItem="TwoGangTwoWaySwitch"
          defaultValue={defaultValueTwoGangLightSwitch}
          image={TwoGangLightSwitchImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="3 gang light switch"
          registerItem="ThreeGangTwoWaySwitch"
          defaultValue={defaultValueThreeGangLightSwitch}
          image={ThreeGangLightSwitchImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="4 gang light switch"
          registerItem="FourGangTwoWaySwitch"
          defaultValue={defaultValueFourGangLightSwitch}
          image={FourGangLightSwitchImage}
        />
      </div>
      <h3 className="text-3xl font-bold mt-14">Dimmers</h3>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="1 gang dimmer switch"
          registerItem="OneGangDimmerSwitch"
          defaultValue={defaultValueOneGangDimmerSwitch}
          image={OneGangDimmerSwitchImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="2 gang dimmer switch"
          registerItem="TwoGangDimmerSwitch"
          defaultValue={defaultValueTwoGangDimmerSwitch}
          image={TwoGangDimmerSwitchImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="3 gang dimmer switch"
          registerItem="ThreeGangDimmerSwitch"
          defaultValue={defaultValueThreeGangDimmerSwitch}
          image={ThreeGangDimmerSwitchImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="4 gang dimmer switch"
          registerItem="FourGangDimmerSwitch"
          defaultValue={defaultValueFourGangDimmerSwitch}
          image={FourGangDimmerSwitchImage}
        />
      </div>
      <h3 className="text-3xl font-bold mt-14">Bathroom lights</h3>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="Satin chrome downlight"
          registerItem="SatinChromeBathroomDownlight"
          defaultValue={defaultValueSatinChromeBathroomDownlight}
          image={SatinChromeBathroomDownlightImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="White downlight"
          registerItem="WhiteBathroomDownlight"
          defaultValue={defaultValueWhiteBathroomDownlight}
          image={WhiteBathroomDownlightImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="Standard bathroom light"
          registerItem="LedBathroomlight"
          defaultValue={defaultValueLedBathroomLight}
          image={LedBathroomLightImage}
        />
        {/* <Quantity
          setValue={setValue}
          register={register}
          label="Supply your own light fitting"
          registerItem="customerSuppliedLightFittingBathroom"
          defaultValue={defaultValueCustomerSuppliedLightFittingBathroom}
        /> */}
      </div>
      <h3 className="text-3xl font-bold mt-14">Light fittings</h3>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label={`6" pendant`}
          registerItem="SixInchPendantLightFitting"
          defaultValue={defaultValueSixInchPendantLightFitting}
          image={SixInchPendantLightFittingImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label={`Satin chrome downlight`}
          registerItem="SatinChromeDownlight"
          defaultValue={defaultValueSatinChromeDownlight}
          image={SatinChromeDownlightFittingImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label={`White downlight`}
          registerItem="WhiteDownlight"
          defaultValue={defaultValueWhiteDownlight}
          image={WhiteDownlightFittingImage}
        />
        {/* <Quantity
          setValue={setValue}
          register={register}
          label="Supply your own light fitting"
          registerItem="customerSuppliedLightFitting"
          defaultValue={defaultValueCustomerSuppliedLightFitting}
        /> */}
      </div>
    </div>
  );
}
