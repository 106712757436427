import Quantity from "../../UI/Quantity";
import CoaxImage from "../../../images/products/TV Socket.jpg";
import SingleDataImage from "../../../images/products/Single Data Socket.jpg";
import DoubleDataImage from "../../../images/products/Double Data Socket.jpg";
export default function TvData({
  register,
  setValue,
  defaultValueCoax,
  defaultValueSingleRJ45,
  defaultValueDoubleRJ45,
}) {
  return (
    <div>
      <h2 className="text-3xl font-bold mb-14">
        What TV & Data requirements do you have?
      </h2>
      <div className="mt-6 flex flex-wrap gap-12">
        <Quantity
          setValue={setValue}
          register={register}
          label="How many COAX sockets do you need?"
          registerItem="COAX"
          defaultValue={defaultValueCoax}
          image={CoaxImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many single data connectors do you need?"
          registerItem="singleRJ45"
          defaultValue={defaultValueSingleRJ45}
          image={SingleDataImage}
        />
        <Quantity
          setValue={setValue}
          register={register}
          label="How many double data connectors do you need?"
          registerItem="doubleRJ45"
          defaultValue={defaultValueDoubleRJ45}
          image={DoubleDataImage}
        />
      </div>
    </div>
  );
}
