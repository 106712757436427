import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../Main";
import Loader from "../UI/Loader";
import TextInput from "../UI/TextInput";
import { WebhookSender } from "./WebhookSender";
import {
  CARBON_MONOXIDE,
  CONSUMER_UNIT,
  EICR,
  LED_LIGHTING,
  REWIRING,
  SMOKE_DETECTOR,
  STAIR_LIGHTING,
  getCostCentre,
} from "../../utils/utils";
import InfoBox from "../UI/InfoBox";
import TextArea from "../UI/TextArea";
import TagManager from "react-gtm-module";
const imageTypeRegex = /image\/(png|jpg|jpeg|HEIC)/gm;
export default function InfoForm() {
  const {
    serviceData,
    setForm,
    setForwardStepTitle,
    setForwardStep,
    formType,
    setBackStep,
    images,
    setImages,
    customerDetails,
  } = useContext(AppContext);
  const { register, handleSubmit } = useForm({
    defaultValues: customerDetails ? customerDetails : {},
  });
  const [imageFiles, setImageFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    setForwardStepTitle("Submit contact form");
    setForwardStep(() => () => {
      const isValid = formRef?.current?.reportValidity();
      if (isValid) {
        formRef?.current?.dispatchEvent(
          new Event("submit", {
            cancelable: true,
            bubbles: true,
            composed: true,
          })
        );
      }
    });
    switch (formType) {
      case REWIRING:
        setBackStep(() => () => setForm(1));
        break;
      case EICR:
        setBackStep(() => () => setForm(2));
        break;
      case CONSUMER_UNIT:
        setBackStep(() => () => setForm(3));
        break;
      case SMOKE_DETECTOR:
        setBackStep(() => () => setForm(4));
        break;
      case CARBON_MONOXIDE:
        setBackStep(() => () => setForm(5));
        break;
      case STAIR_LIGHTING:
        setBackStep(() => () => setForm(6));
        break;
      case LED_LIGHTING:
        setBackStep(() => () => setForm(7));
        break;
      default:
        setBackStep(() => () => setForm(0));
    }
  }, []);

  //useEffect for image files
  useEffect(() => {
    const images = [],
      fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if (images.length === imageFiles.length && !isCancel) {
            setImages(images);
          }
        };
        fileReader.readAsDataURL(file);
      });
    }
    return () => {
      isCancel = true;
      fileReaders.forEach((fileReader) => {
        if (fileReader.readyState === 1) {
          fileReader.abort();
        }
      });
    };
  }, [imageFiles]);

  const changeHandler = (e) => {
    const { files } = e.target;
    const validImageFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.match(imageTypeRegex)) {
        validImageFiles.push(file);
      }
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      return;
    }
    alert("Selected images are not of valid type!");
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const leadRequest = new Request("/create-lead", {
        method: "POST",
        body: JSON.stringify({
          customerDetails: data,
          jobDescription: serviceData.jobDescription,
          images,
          globalCostCenterID: getCostCentre(
            formType,
            window.location.href.includes("harkinsandvickers") ? 3 : 2,
            formType === STAIR_LIGHTING &&
              serviceData.data?.requirements === "ledConversion"
          ),
        }),
      });
      await fetch(leadRequest);
      await WebhookSender({ customerDetails: data }); // Call WebhookSender with the data object
      TagManager.dataLayer({
        dataLayer: {
          event: "lead-created",
        },
      });
      setLoading(false);
      setForm(11);
    } catch (error) {
      setLoading(false);
      console.log(error);
      throw error;
    }
  };

  const renderInfoBox = () => {
    switch (formType) {
      case EICR || CONSUMER_UNIT:
        return (
          <InfoBox
            title="We require some more information"
            content="Our team require more information in order to provide an accurate quote. Please upload a photo of your consumer unit (fusebox) or provide us with the number of circuits your consumer unit has and one of our dedicated team will be in touch soon."
          />
        );
      default:
        return (
          <InfoBox
            title="We require some more information"
            content="Our team require more information in order to provide an accurate quote. Please provide us with information of what you require along with any supporting photos and one of our dedicated team will be in touch soon."
          />
        );
    }
  };
  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="mb-6 text-2xl font-bold lg:mb-12 lg:text-4xl">
        Let's take some details
      </h2>
      {loading && <Loader />}
      {!loading && (
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          {renderInfoBox()}
          <div className="grid gap-4 grid-cols-1 mt-5">
            <div>
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <TextInput
                  register={register}
                  registerItem="firstName"
                  type="text"
                  label="First name"
                  placeholder="John"
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="lastName"
                  type="text"
                  label="Last name"
                  placeholder="Smith"
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="businessName"
                  type="text"
                  label="Business name"
                  placeholder="Smith Ltd"
                />
                <TextInput
                  register={register}
                  registerItem="email"
                  type="email"
                  label="Email address"
                  placeholder="email@email.com"
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="phone"
                  type="tel"
                  label="Phone number"
                  placeholder={
                    window.location.href.includes("harkinsandvickers")
                      ? "0141 555 5555"
                      : "0131 555 5555"
                  }
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="streetAddress"
                  type="text"
                  label="Street address"
                  placeholder={
                    window.location.href.includes("harkinsandvickers")
                      ? "18 Waterloo Street"
                      : "18 Chambers Street"
                  }
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="city"
                  type="text"
                  label="City"
                  placeholder={
                    window.location.href.includes("harkinsandvickers")
                      ? "Glasgow"
                      : "Edinburgh"
                  }
                  required={true}
                />
                <TextInput
                  register={register}
                  registerItem="postCode"
                  type="text"
                  label="Post code"
                  placeholder="EH2 2YB"
                  required={true}
                />
              </div>
              <TextArea
                register={register}
                registerItem="customerNotes"
                type="text"
                label="Extra Details"
                placeholder={""}
              />
              {formType !== REWIRING && (
                <>
                  <div className="mb-6 mt-6">
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="file"
                        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500">
                            JPG, PNG, HEIC
                          </p>
                        </div>
                        <input
                          id="file"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={changeHandler}
                          className="hidden"
                          multiple
                        />
                      </label>
                    </div>
                  </div>

                  {images.length > 0 ? (
                    <div className="grid grid-cols-12 gap-4 mb-6">
                      <button
                        className="text-red-400"
                        type="button"
                        onClick={() => {
                          setImages([]);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          class="feather feather-trash-2"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                        </svg>
                      </button>
                      {images.map((image, idx) => {
                        return (
                          <p key={idx}>
                            {" "}
                            <img
                              className="aspect-square object-cover rounded-lg"
                              src={image}
                              alt=""
                            />{" "}
                          </p>
                        );
                      })}
                    </div>
                  ) : null}
                </>
              )}
              <div className="flex items-start mb-6">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-305 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <label
                  htmlFor="remember"
                  className="ml-2 text-sm font-medium text-black"
                >
                  I agree with the{" "}
                  <a
                    href="https://www.harkinsandvickers.co.uk/terms-conditions/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-black underline"
                  >
                    terms and conditions
                  </a>
                  .
                </label>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
