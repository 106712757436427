import { useContext, useEffect, useMemo, useState } from "react";
import {
  CARBON_MONOXIDE,
  CONSUMER_UNIT,
  COULD_NOT_FIND_TIMESLOT,
  EICR,
  LED_LIGHTING,
  REWIRING,
  SMOKE_DETECTOR,
  STAIR_LIGHTING,
  getCostCentre,
} from "../../utils/utils";
import { AppContext } from "../Main";
import Loader from "../UI/Loader";
import InfoBox from "../UI/InfoBox";
import TagManager from "react-gtm-module";

export default function Schedule() {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [times, setTimes] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
  );

  const {
    formType,
    serviceData,
    customerDetails,
    setForm,
    setServiceData,
    setForwardStep,
    setForwardStepTitle,
    setBackStep,
    setInfoFormReason,
  } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);

  const timeBlockRequired =
    formType === CONSUMER_UNIT
      ? 150
      : serviceData.breakdown.reduce((acc, cur) => {
          return acc + cur.time * cur.qty;
        }, 0);

  const scheduleRequest = useMemo(() => {
    const endDate = new Date(
      new Date(startDate).getTime() + 7 * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 10);
    if (formType === REWIRING) {
      const url = new URL(
        "/fetch-free-timeblocks-for-quote",
        window.location.origin
      );
      const params = new URLSearchParams({
        startDate,
        endDate,
      });
      url.search = params;
      return new Request(url, { method: "GET" });
    } else {
      const url = new URL("/fetch-free-timeblocks", window.location.origin);
      const params = new URLSearchParams({
        startDate,
        endDate,
        timeBlockRequired,
      });
      url.search = params;
      return new Request(url, { method: "GET" });
    }
  }, [startDate, timeBlockRequired, formType]);

  useEffect(() => {
    setForwardStepTitle("Book selected time");
    setBackStep(() => () => setForm(9));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(scheduleRequest);
        const data = await response.json();
        setTimes(data);
        setLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error accordingly (e.g., show a message or update the component state)
      }
    };

    fetchData();
  }, [scheduleRequest]);

  const makeBookingRequest = useMemo(() => {
    if (!selectedTime) {
      return null;
    }
    const random = Math.floor(Math.random() * selectedTime.length);
    const timeToBook = selectedTime[random];
    const block = {
      start: timeToBook.start,
      end: timeToBook.end,
    };
    if (formType === REWIRING) {
      const prebuilds = serviceData.breakdown.reduce((accumulator, item) => {
        item.prebuildIds.forEach((prebuildId) => {
          const existingItem = accumulator.find((x) => x.ID === prebuildId);

          if (existingItem) {
            existingItem.quantity =
              Number(existingItem.quantity) + Number(item.qty);
          } else {
            accumulator.push({ ID: prebuildId, quantity: item.qty });
          }
        });

        return accumulator;
      }, []);
      return new Request("/create-quote", {
        method: "POST",
        body: JSON.stringify({
          customerDetails,
          prebuilds: prebuilds,
          schedule: {
            staffID: timeToBook.employeeID,
            date: selectedDate,
            block,
          },
          globalCostCenterID: getCostCentre(
            formType,
            window.location.href.includes("harkinsandvickers") ? 3 : 2,
            formType === STAIR_LIGHTING &&
              serviceData.data.requirements === "ledConversion"
          ),
        }),
      });
    } else {
      const prebuilds = serviceData.breakdown.reduce((accumulator, item) => {
        item.prebuildIds.forEach((prebuildId) => {
          const existingItem = accumulator.find((x) => x.ID === prebuildId);

          if (existingItem) {
            existingItem.quantity =
              Number(existingItem.quantity) + Number(item.qty);
          } else {
            accumulator.push({ ID: prebuildId, quantity: item.qty });
          }
        });

        return accumulator;
      }, []);
      return new Request("/create-booking", {
        method: "POST",
        body: JSON.stringify({
          customerDetails: customerDetails,
          prebuilds: prebuilds,
          schedule: {
            staffID: timeToBook.employeeID,
            date: selectedDate,
            block,
          },
          globalCostCenterID: getCostCentre(
            formType,
            window.location.href.includes("harkinsandvickers") ? 3 : 2,
            formType === STAIR_LIGHTING &&
              serviceData.data.requirements === "ledConversion"
          ),
        }),
      });
    }
  }, [selectedDate, selectedTime, customerDetails, formType, serviceData]);

  useEffect(() => {
    if (!loaded) {
      setForwardStep(undefined);
      return;
    }
    if (selectedTime) {
      const makeBooking = async (data) => {
        try {
          setLoaded(false);
          await fetch(makeBookingRequest);
          if (formType === REWIRING) {
            TagManager.dataLayer({
              dataLayer: {
                event: "quote-created",
              },
            });
          } else {
            TagManager.dataLayer({
              dataLayer: {
                event: "job-created",
              },
            });
          }
          setForm(11);
        } catch (error) {
          console.log(error);
        }
        setLoaded(true);
      };

      setForwardStep(() => () => makeBooking());
    } else {
      setForwardStep(undefined);
    }
  }, [selectedTime, setForwardStep, makeBookingRequest, setForm, loaded]);

  const moveStartDateForward = () => {
    setLoaded(false);
    setSelectedDate("");
    setSelectedTime("");
    const newStartDate = new Date(
      new Date(startDate).getTime() + 7 * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 10);
    setStartDate(newStartDate);
  };

  const moveStartDateBackward = () => {
    setLoaded(false);
    setSelectedDate("");
    setSelectedTime("");
    const newStartDate = new Date(
      new Date(startDate).getTime() - 7 * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 10);
    setStartDate(newStartDate);
  };

  const onDateClick = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const onTimeClick = (timeBlocks) => {
    setSelectedTime(timeBlocks);
  };

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const isStartDateTwoDaysHence =
    startDate ===
    new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10);

  const renderDates = () => {
    return Object.keys(times).map((date) => {
      const hasAMSlots = times[date].AM.length > 0;
      const hasPMSlots = times[date].PM.length > 0;
      const isDisabled = !hasAMSlots && !hasPMSlots;
      const isSelected = selectedDate === date;

      return (
        <button
          key={date}
          className={`${
            isDisabled
              ? "text-gray-300 bg-white font-medium border-2 border-gray p-5 rounded-md"
              : "text-black bg-white font-medium border-2 border-black p-5 rounded-md hover:text-white hover:bg-black"
          } ${isSelected ? "text-gray-50 bg-black" : ""}`}
          disabled={isDisabled}
          onClick={() => onDateClick(date)}
        >
          {formatDate(date)}
        </button>
      );
    });
  };

  const renderTimeOptions = () => {
    if (!selectedDate) return null;

    const { AM, PM } = times[selectedDate];
    console.log(selectedTime);

    return (
      <div className="mt-4 flex gap-2">
        <button
          className={` font-medium border-2 p-5 ${
            AM.length === 0
              ? "bg-white text-gray-300 border-gray"
              : selectedTime === AM
              ? "border-black bg-black text-white"
              : "bg-white hover:bg-black hover:text-white text-black border-black"
          } p-1 rounded-md `}
          onClick={() => onTimeClick(AM)}
          disabled={AM.length === 0}
        >
          Morning (AM)
        </button>
        <button
          className={` font-medium border-2 p-5 ${
            PM.length === 0
              ? "bg-white text-gray-300 border-gray"
              : selectedTime === PM
              ? "border-black bg-black text-white"
              : "bg-white hover:bg-black hover:text-white text-black border-black"
          } p-1 rounded-md `}
          onClick={() => onTimeClick(PM)}
        >
          Afternoon (PM)
        </button>
      </div>
    );
  };

  const renderInfoBox = () => {
    switch (formType) {
      case REWIRING:
        return (
          <InfoBox
            title="Rewiring booking"
            content={
              <p>
                You are about to book an appointment with our estimator. We will
                carry out an on-site survey of your property to ensure the
                information within your quote is accurate and talk through the
                rewiring process with you. Please note, this quote may require
                an update once we have surveyed your home.
              </p>
            }
          />
        );
      case EICR:
        return (
          <InfoBox
            title="Booking process"
            content={
              <>
                <p>
                  Please schedule an appointment for one of our electricians to
                  attend. The EICR normally takes 2-3 hours depending on the
                  property size.
                </p>
                <br />
                <p>
                  Once the EICR is completed, our NICEIC qualified supervisor
                  will review the document, if the results of the EICR are
                  satisfactory then we will sign it off. We aim for this to take
                  3 working days.
                </p>
                <br />
                <p>
                  If the results of your EICR are unsatisfactory, one of our
                  estimators will be in touch with a new quotation to carry out
                  necessary remedial work required in order to obtain a
                  satisfactory EICR.
                </p>
              </>
            }
          />
        );
      case CONSUMER_UNIT:
        return (
          <InfoBox
            title={"Booking process"}
            content={
              <>
                <p>
                  Please schedule an appointment for one of our electricians to
                  perform a preliminary EICR (Electrical Installation Condition
                  Report). This is to ensure that the installation meets the
                  current standards and that there are no faults which could
                  cause tripping of a new system.
                </p>
                <br />
                <p>
                  If the results of the EICR are satisfactory we will get in
                  touch and book a follow up appointment to install the new
                  consumer unit. If the results of the EICR were unsatisfactory,
                  then one of our estimators will provide an updated quote for
                  installing the new consumer unit which will include any
                  remedial work that is required.
                </p>
              </>
            }
          />
        );
      case CARBON_MONOXIDE:
        return (
          <InfoBox
            title={"Booking process"}
            description={
              <p>
                Please schedule an appointment for one of our electricians to
                attend on your desired date and time to carry out your carbon
                monoxide detector installation.
              </p>
            }
          />
        );
      case LED_LIGHTING:
        return (
          <InfoBox
            title={"Booking process"}
            content={
              <p>
                Please schedule an appointment for one of our electricians to
                attend on your desired date and time to carry out your LED
                lighting installation.
              </p>
            }
          />
        );
      case SMOKE_DETECTOR:
        return (
          <InfoBox
            title={"Booking process"}
            content={
              <p>
                Please schedule an appointment for one of our electricians to
                attend on your desired date and time to carry out your smoke
                detector installation.
              </p>
            }
          />
        );
      case STAIR_LIGHTING:
        return (
          <InfoBox
            title={"Booking process"}
            content={
              <p>
                Please schedule an appointment for one of our electricians to
                attend on your desired date and time to carry out your stair
                lighting installation.
              </p>
            }
          />
        );
      default:
        return (
          <InfoBox
            title="Booking"
            content="Please make your booking by selecting a time slot below."
          />
        );
    }
  };

  return (
    <div className="max-w-2xl mx-auto overflow-hidden">
      {!loaded && <Loader />}
      {loaded && (
        <>
          <h2 className="text-3xl text-center font-bold mb-14">
            Let's book your appointment!
          </h2>
          {renderInfoBox()}
          <button
            onClick={() => {
              setServiceData({
                ...serviceData,
                description: `Website lead for ${formType}
                  Customer could not find a suitable time slot.
                  Details of attempted booking:
                  ${JSON.stringify(serviceData, null, 2)}`,
              });
              setInfoFormReason(COULD_NOT_FIND_TIMESLOT);
              setForm(8);
            }}
            className="px-4 py-1 rounded-t-md font-medium false bg-yellow-100 w-full hover:bg-green-200"
          >
            Cant find a suitable time slot? Send us a message ✉️
          </button>
          <div className="bg-white rounded-md p-8">
            <div>
              <div className="flex justify-between mb-8">
                <button
                  onClick={() => moveStartDateBackward()}
                  className={`${
                    isStartDateTwoDaysHence
                      ? "text-gray-400"
                      : "text-white px-2 py-1 font-medium border-2 border-black bg-black rounded-md"
                  }`}
                  disabled={isStartDateTwoDaysHence}
                >
                  Earlier
                </button>
                <button
                  className="text-white px-2 py-1 font-medium border-2 border-black bg-black rounded-md"
                  onClick={() => moveStartDateForward()}
                >
                  Later
                </button>
              </div>
              {!Boolean(times) && (
                <h3 className="text-2xl font-bold mb-6">
                  No appointments available for this time period
                </h3>
              )}
              {Boolean(times) && (
                <>
                  <h3 className="text-2xl font-bold mb-6">
                    Select a suitable date
                  </h3>
                  <div className="flex gap-2 flex-wrap">{renderDates()}</div>
                </>
              )}
            </div>

            {Boolean(times) && (
              <>
                {selectedDate && (
                  <h3 className="text-2xl font-bold my-6">
                    Select a suitable time
                  </h3>
                )}

                {renderTimeOptions()}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
