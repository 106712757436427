import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../Main";
import Bedrooms from "../formParts/Bedrooms";
import Sockets from "../formParts/rewiring/Sockets";
import Power from "../formParts/rewiring/Power";
import Lighting from "../formParts/rewiring/Lighting";
import Fans from "../formParts/rewiring/Fans";
import SmokeCarbon from "../formParts/rewiring/SmokeCarbon";
import TvData from "../formParts/rewiring/TvData";
import Breakdown from "../formParts/Breakdown";
import EICtesting from "../formParts/rewiring/EICtesting";
import Loader from "../UI/Loader";
import prebuildData from "../../data.json";
import { FOUR_BEDROOM_SELECTED, REWIRING } from "../../utils/utils";

export default function Rewiring() {
  const {
    setForm,
    setServiceData,
    setFormType,
    step,
    setStep,
    setForwardStep,
    setBackStep,
    setForwardStepTitle,
    formDefaultValues,
    setFormDefaultValues,
    breakdown,
    setBreakdown,
    setInfoFormReason,
  } = useContext(AppContext);

  //State for managing which step the form is currently at

  //useState to store endpoint data
  const [simProData, setSimProData] = useState({
    twinSocket: [],
    twinSocketUsb: [],
    switchedSingleSocket: [],
    fusedAlarm: [],
    electricHob: [],
    electricOven: [],
    kitchenApplicance: [],
    electricShower: [],
    immersionTank: [],
    boiler: [],
    shaverSocket: [],
    _1Gang2WaySwitch: [],
    _2Gang2WaySwitch: [],
    _3Gang2WaySwitch: [],
    _4Gang2WaySwitch: [],
    _1GangDimmerSwitch: [],
    _2GangDimmerSwitch: [],
    _3GangDimmerSwitch: [],
    _4GangDimmerSwitch: [],
    bathroomSatinDownLight: [],
    bathroomWhiteDownLight: [],
    bathroomLEDlightFitting: [],
    bathroomClientIssueLightFitting: [],
    _6InchPendant: [],
    satinDimmableDownLight: [],
    whiteDimmableDownLight: [],
    clientIssueLightFitting: [],
    _4InchStandardFan: [],
    _4InchTimerFan: [],
    opticalSmokeDetector: [],
    heatDetector: [],
    carbonMonoxideDetector: [],
    testSwitch: [],
    coax: [],
    singleRJ45: [],
    doubleRJ45: [],
    _8WayConsumerUnit: [],
    _10WayConsumerUnit: [],
    _14WayConsumerUnit: [],
    EIC8and10Way: [],
    EIC14Way: [],
    earthBonding: [],
    consumables: [],
    BWICS: [],
    wasteDisposal: [],
  });

  // SimPro data loaded state
  const [loaded, setLoaded] = useState(false);

  const formRef = useRef();

  useEffect(() => setFormType(REWIRING));

  // React Hook Form
  const { register, handleSubmit, watch, setValue, formState, getValues } =
    useForm({
      defaultValues: formDefaultValues
        ? formDefaultValues
        : {
            applianceSwitch: "0",
            shaverSocket: "0",
            singleSocket: "0",
            twinSocket: "0",
            coax: "0",
            smokeDetector: "0",
            carbonDetector: "0",
            power: [],
            applianceSwitchQuantity: "1",
          },
    });

  useEffect(() => {
    if (formDefaultValues) {
      // Form has been submitted so user has navigated back from after form submission
      setStep(7);
      setBackStep(() => () => setStep(6));
      setForwardStep(() => () => setForm(8));
    }
  }, [formDefaultValues]);

  // watching form values for navigation validation
  const bedroomsFormValue = watch("bedrooms");
  const eicCertificateFormValue = watch("eicCertificate");
  const powerFormValue = watch("power");
  const applianceSwitchQuantity = watch("applianceSwitchQuantity");

  useEffect(() => {
    setForwardStepTitle("Next");
    switch (step) {
      case 0:
        if (bedroomsFormValue) {
          if (bedroomsFormValue === "4") {
            setForwardStep(() => () => {
              setServiceData({
                description: `4 bedroom property EICR`,
              });
              setInfoFormReason(FOUR_BEDROOM_SELECTED);
              setForm(8);
            });
            return;
          }
          setForwardStep(() => () => setStep(1));
        }
        setBackStep(undefined);
        break;
      case 1:
        setForwardStep(() => () => setStep(2));
        setBackStep(() => () => setStep(0));
        break;
      case 2:
        setForwardStep(() => () => setStep(3));
        setBackStep(() => () => setStep(1));
        break;
      case 3:
        setForwardStep(() => () => setStep(4));
        setBackStep(() => () => setStep(2));
        break;
      case 4:
        setForwardStep(() => () => setStep(5));
        setBackStep(() => () => setStep(3));
        break;
      case 5:
        setForwardStep(() => () => setStep(6));
        setBackStep(() => () => setStep(4));
        break;
      case 6:
        setForwardStepTitle("Get my quote");
        setForwardStep(
          () => () =>
            formRef.current.dispatchEvent(
              new Event("submit", { cancelable: true, bubbles: true })
            )
        );
        setBackStep(() => () => setStep(5));
        break;
      case 7:
        setForwardStep(() => () => setForm(9));
        setBackStep(() => () => setStep(6));
        break;
      default:
        setForwardStep(undefined);
        setBackStep(undefined);
        break;
    }
  }, [
    step,
    bedroomsFormValue,
    eicCertificateFormValue,
    setForwardStep,
    setBackStep,
    setForwardStepTitle,
    setForm,
    setStep,
  ]);

  // fetch data from SimPro
  useEffect(() => {
    prebuildData.forEach((prebuild) => {
      if (prebuild.ID === 48861) {
        setSimProData((prevState) => ({
          ...prevState,
          twinSocket: prebuild,
        }));
      }
      if (prebuild.ID === 48862) {
        setSimProData((prevState) => ({
          ...prevState,
          twinSocketUsb: prebuild,
        }));
      }
      if (prebuild.ID === 48863) {
        setSimProData((prevState) => ({
          ...prevState,
          switchedSingleSocket: prebuild,
        }));
      }
      if (prebuild.ID === 48865) {
        setSimProData((prevState) => ({
          ...prevState,
          fusedAlarm: prebuild,
        }));
      }
      if (prebuild.ID === 48868) {
        setSimProData((prevState) => ({
          ...prevState,
          electricHob: prebuild,
        }));
      }
      if (prebuild.ID === 48872) {
        setSimProData((prevState) => ({
          ...prevState,
          electricOven: prebuild,
        }));
      }
      if (prebuild.ID === 48875) {
        setSimProData((prevState) => ({
          ...prevState,
          kitchenApplicance: prebuild,
        }));
      }
      if (prebuild.ID === 48869) {
        setSimProData((prevState) => ({
          ...prevState,
          electricShower: prebuild,
        }));
      }
      if (prebuild.ID === 48871) {
        setSimProData((prevState) => ({
          ...prevState,
          immersionTank: prebuild,
        }));
      }
      if (prebuild.ID === 48864) {
        setSimProData((prevState) => ({
          ...prevState,
          boiler: prebuild,
        }));
      }
      if (prebuild.ID === 48873) {
        setSimProData((prevState) => ({
          ...prevState,
          shaverSocket: prebuild,
        }));
      }
      if (prebuild.ID === 48878) {
        setSimProData((prevState) => ({
          ...prevState,
          _1Gang2WaySwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48879) {
        setSimProData((prevState) => ({
          ...prevState,
          _2Gang2WaySwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48880) {
        setSimProData((prevState) => ({
          ...prevState,
          _3Gang2WaySwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48881) {
        setSimProData((prevState) => ({
          ...prevState,
          _4Gang2WaySwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48882) {
        setSimProData((prevState) => ({
          ...prevState,
          _1GangDimmerSwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48883) {
        setSimProData((prevState) => ({
          ...prevState,
          _2GangDimmerSwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48884) {
        setSimProData((prevState) => ({
          ...prevState,
          _3GangDimmerSwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48915) {
        setSimProData((prevState) => ({
          ...prevState,
          _4GangDimmerSwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48886) {
        setSimProData((prevState) => ({
          ...prevState,
          bathroomSatinDownLight: prebuild,
        }));
      }
      if (prebuild.ID === 48887) {
        setSimProData((prevState) => ({
          ...prevState,
          bathroomWhiteDownLight: prebuild,
        }));
      }
      if (prebuild.ID === 48888) {
        setSimProData((prevState) => ({
          ...prevState,
          bathroomLEDlightFitting: prebuild,
        }));
      }
      if (prebuild.ID === 48889) {
        setSimProData((prevState) => ({
          ...prevState,
          bathroomClientIssueLightFitting: prebuild,
        }));
      }
      if (prebuild.ID === 48890) {
        setSimProData((prevState) => ({
          ...prevState,
          _6InchPendant: prebuild,
        }));
      }
      if (prebuild.ID === 48891) {
        setSimProData((prevState) => ({
          ...prevState,
          satinDimmableDownLight: prebuild,
        }));
      }
      if (prebuild.ID === 48892) {
        setSimProData((prevState) => ({
          ...prevState,
          whiteDimmableDownLight: prebuild,
        }));
      }
      if (prebuild.ID === 48893) {
        setSimProData((prevState) => ({
          ...prevState,
          clientIssueLightFitting: prebuild,
        }));
      }
      if (prebuild.ID === 48894) {
        setSimProData((prevState) => ({
          ...prevState,
          _4InchStandardFan: prebuild,
        }));
      }
      if (prebuild.ID === 48895) {
        setSimProData((prevState) => ({
          ...prevState,
          _4InchTimerFan: prebuild,
        }));
      }
      if (prebuild.ID === 48901) {
        setSimProData((prevState) => ({
          ...prevState,
          opticalSmokeDetector: prebuild,
        }));
      }
      if (prebuild.ID === 48902) {
        setSimProData((prevState) => ({
          ...prevState,
          heatDetector: prebuild,
        }));
      }
      if (prebuild.ID === 48903) {
        setSimProData((prevState) => ({
          ...prevState,
          carbonMonoxideDetector: prebuild,
        }));
      }
      if (prebuild.ID === 48904) {
        setSimProData((prevState) => ({
          ...prevState,
          testSwitch: prebuild,
        }));
      }
      if (prebuild.ID === 48919) {
        setSimProData((prevState) => ({
          ...prevState,
          coax: prebuild,
        }));
      }
      if (prebuild.ID === 48920) {
        setSimProData((prevState) => ({
          ...prevState,
          singleRJ45: prebuild,
        }));
      }
      if (prebuild.ID === 48921) {
        setSimProData((prevState) => ({
          ...prevState,
          doubleRJ45: prebuild,
        }));
      }
      if (prebuild.ID === 55439) {
        setSimProData((prevState) => ({
          ...prevState,
          _8WayConsumerUnit: prebuild,
        }));
      }
      if (prebuild.ID === 55440) {
        setSimProData((prevState) => ({
          ...prevState,
          _10WayConsumerUnit: prebuild,
        }));
      }
      if (prebuild.ID === 55441) {
        setSimProData((prevState) => ({
          ...prevState,
          _14WayConsumerUnit: prebuild,
        }));
      }
      if (prebuild.ID === 48899) {
        setSimProData((prevState) => ({
          ...prevState,
          EIC8and10Way: prebuild,
        }));
      }
      if (prebuild.ID === 48900) {
        setSimProData((prevState) => ({
          ...prevState,
          EIC14Way: prebuild,
        }));
      }
      if (prebuild.ID === 48905) {
        setSimProData((prevState) => ({
          ...prevState,
          earthBonding: prebuild,
        }));
      }
      if (prebuild.ID === 48907) {
        setSimProData((prevState) => ({
          ...prevState,
          consumables: prebuild,
        }));
      }
      if (prebuild.ID === 48908) {
        setSimProData((prevState) => ({
          ...prevState,
          BWICS: prebuild,
        }));
      }
      if (prebuild.ID === 48909) {
        setSimProData((prevState) => ({
          ...prevState,
          wasteDisposal: prebuild,
        }));
      }
    });
    setLoaded(true);
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    function calculateTotals() {
      // Initilise breakdown array
      let breakdown = [];

      if (data.bedrooms === "1") {
        breakdown.push({
          name: "8 Way Consumer Unit",
          qty: 1,
          price: simProData?._8WayConsumerUnit?.TotalEx,
          time: simProData?._8WayConsumerUnit?.LaborTime,
          prebuildIds: [simProData?._8WayConsumerUnit?.ID],
        });
      }

      if (data.bedrooms === "2") {
        breakdown.push({
          name: "10 Way Consumer Unit",
          qty: 1,
          price: simProData?._10WayConsumerUnit?.TotalEx,
          time: simProData?._10WayConsumerUnit?.LaborTime,
          prebuildIds: [simProData?._10WayConsumerUnit?.ID],
        });
      }

      if (data.bedrooms >= "3") {
        breakdown.push({
          name: "14 Way Consumer Unit",
          qty: 1,
          price: simProData?._14WayConsumerUnit?.TotalEx,
          time: simProData?._14WayConsumerUnit?.LaborTime,
          prebuildIds: [simProData?._14WayConsumerUnit?.ID],
        });
      }

      if (data.singleSocket > 0) {
        breakdown.push({
          name: "Single socket",
          qty: data.singleSocket,
          price: simProData?.switchedSingleSocket?.TotalEx * data.singleSocket,
          time: simProData?.switchedSingleSocket?.LaborTime * data.singleSocket,
          prebuildIds: [simProData?.switchedSingleSocket?.ID],
        });
      }

      if (data.twinSocket > 0) {
        breakdown.push({
          name: "Twin socket",
          qty: data.twinSocket,
          price: simProData?.twinSocket?.TotalEx * data.twinSocket,
          time: simProData?.twinSocket?.LaborTime * data.twinSocket,
          prebuildIds: [simProData?.twinSocket?.ID],
        });
      }

      if (data.power && data.power.includes("electric-oven")) {
        breakdown.push({
          name: "Electric oven",
          qty: 1,
          price: simProData?.electricOven?.TotalEx,
          time: simProData?.electricOven?.LaborTime,
          prebuildIds: [simProData?.electricOven?.ID],
        });
      }

      if (data.power && data.power.includes("electric-hob")) {
        breakdown.push({
          name: "Electric hob",
          qty: 1,
          price: simProData?.electricHob?.TotalEx,
          time: simProData?.electricHob?.LaborTime,
          prebuildIds: [simProData?.electricHob?.ID],
        });
      }

      if (data.power && data.power.includes("electric-shower")) {
        breakdown.push({
          name: "Electric shower",
          qty: 1,
          price: simProData?.electricShower?.TotalEx,
          time: simProData?.electricShower?.LaborTime,
          prebuildIds: [simProData?.electricShower?.ID],
        });
      }

      if (data.power && data.power.includes("immersion-tank")) {
        breakdown.push({
          name: "Immersion tank",
          qty: 1,
          price: simProData?.immersionTank?.TotalEx,
          time: simProData?.immersionTank?.LaborTime,
          prebuildIds: [simProData?.immersionTank?.ID],
        });
      }

      if (data.power && data.power.includes("boiler")) {
        breakdown.push({
          name: "Boiler",
          qty: 1,
          price: simProData?.boiler?.TotalEx,
          time: simProData?.boiler?.LaborTime,
          prebuildIds: [simProData?.boiler?.ID],
        });
      }

      if (data.power && data.power.includes("shaver-socket")) {
        breakdown.push({
          name: "Shaver socket",
          qty: 1,
          price: simProData?.shaverSocket?.TotalEx,
          time: simProData?.shaverSocket?.LaborTime,
          prebuildIds: [simProData?.shaverSocket?.ID],
        });
      }

      if (data.power && data.power.includes("intruder-alarm")) {
        breakdown.push({
          name: "Intruder alarm",
          qty: 1,
          price: simProData?.fusedAlarm?.TotalEx,
          time: simProData?.fusedAlarm?.LaborTime,
          prebuildIds: [simProData?.fusedAlarm?.ID],
        });
      }

      if (data.power && data.power.includes("fused-boiler-switch")) {
        breakdown.push({
          name: "Fused boiler switch",
          qty: 1,
          price: simProData?.boiler?.TotalEx,
          time: simProData?.boiler?.LaborTime,
          prebuildIds: [simProData?.boiler?.ID],
        });
      }

      if (
        data.power &&
        data.power.includes("applianceSwitch") &&
        data.applianceSwitchQuantity > 0
      ) {
        breakdown.push({
          name: "Appliance switch",
          qty: data.applianceSwitchQuantity,
          price:
            simProData?.kitchenApplicance?.TotalEx *
            data.applianceSwitchQuantity,
          time:
            simProData?.kitchenApplicance?.LaborTime *
            data.applianceSwitchQuantity,
          prebuildIds: [simProData?.kitchenApplicance?.ID],
        });
      }

      if (data.OneGangTwoWaySwitch > 0) {
        breakdown.push({
          name: "One gang two way switch",
          qty: data.OneGangTwoWaySwitch,
          price:
            simProData?._1Gang2WaySwitch?.TotalEx * data.OneGangTwoWaySwitch,
          time:
            simProData?._1Gang2WaySwitch?.LaborTime * data.OneGangTwoWaySwitch,
          prebuildIds: [simProData?._1Gang2WaySwitch?.ID],
        });
      }

      if (data.TwoGangTwoWaySwitch > 0) {
        breakdown.push({
          name: "Two gang two way switch",
          qty: data.TwoGangTwoWaySwitch,
          price:
            simProData?._2Gang2WaySwitch?.TotalEx * data.TwoGangTwoWaySwitch,
          time:
            simProData?._2Gang2WaySwitch?.LaborTime * data.TwoGangTwoWaySwitch,
          prebuildIds: [simProData?._2Gang2WaySwitch?.ID],
        });
      }

      if (data.ThreeGangTwoWaySwitch > 0) {
        breakdown.push({
          name: "Three gang two way switch",
          qty: data.ThreeGangTwoWaySwitch,
          price:
            simProData?._3Gang2WaySwitch?.TotalEx * data.ThreeGangTwoWaySwitch,
          time:
            simProData?._3Gang2WaySwitch?.LaborTime *
            data.ThreeGangTwoWaySwitch,
          prebuildIds: [simProData?._3Gang2WaySwitch?.ID],
        });
      }

      if (data.FourGangTwoWaySwitch > 0) {
        breakdown.push({
          name: "Four gang two way switch",
          qty: data.FourGangTwoWaySwitch,
          price:
            simProData?._4Gang2WaySwitch?.TotalEx * data.FourGangTwoWaySwitch,
          time:
            simProData?._4Gang2WaySwitch?.LaborTime * data.FourGangTwoWaySwitch,
          prebuildIds: [simProData?._4Gang2WaySwitch?.ID],
        });
      }

      if (data.OneGangDimmerSwitch > 0) {
        breakdown.push({
          name: "One gang dimmer switch",
          qty: data.OneGangDimmerSwitch,
          price:
            simProData?._1GangDimmerSwitch?.TotalEx * data.OneGangDimmerSwitch,
          time:
            simProData?._1GangDimmerSwitch?.LaborTime *
            data.OneGangDimmerSwitch,
          prebuildIds: [simProData?._1GangDimmerSwitch?.ID],
        });
      }

      if (data.TwoGangDimmerSwitch > 0) {
        breakdown.push({
          name: "Two gang dimmer switch",
          qty: data.TwoGangDimmerSwitch,
          price:
            simProData?._2GangDimmerSwitch?.TotalEx * data.TwoGangDimmerSwitch,
          time:
            simProData?._2GangDimmerSwitch?.LaborTime *
            data.TwoGangDimmerSwitch,
          prebuildIds: [simProData?._2GangDimmerSwitch?.ID],
        });
      }

      if (data.ThreeGangDimmerSwitch > 0) {
        breakdown.push({
          name: "Three gang dimmer switch",
          qty: data.ThreeGangDimmerSwitch,
          price:
            simProData?._3GangDimmerSwitch?.TotalEx *
            data.ThreeGangDimmerSwitch,
          time:
            simProData?._3GangDimmerSwitch?.LaborTime *
            data.ThreeGangDimmerSwitch,
          prebuildIds: [simProData?._3GangDimmerSwitch?.ID],
        });
      }

      if (data.FourGangDimmerSwitch > 0) {
        breakdown.push({
          name: "Four gang dimmer switch",
          qty: data.FourGangDimmerSwitch,
          price:
            simProData?._4GangDimmerSwitch?.TotalEx * data.FourGangDimmerSwitch,
          time:
            simProData?._4GangDimmerSwitch?.LaborTime *
            data.FourGangDimmerSwitch,
          prebuildIds: [simProData?._4GangDimmerSwitch?.ID],
        });
      }

      if (data.SatinChromeBathroomDownlight > 0) {
        breakdown.push({
          name: "Satin Chrome bathroom downlight",
          qty: data.SatinChromeBathroomDownlight,
          price:
            simProData?.bathroomSatinDownLight?.TotalEx *
            data.SatinChromeBathroomDownlight,
          time:
            simProData?.bathroomSatinDownLight?.LaborTime *
            data.SatinChromeBathroomDownlight,
          prebuildIds: [simProData?.bathroomSatinDownLight?.ID],
        });
      }

      if (data.WhiteBathroomDownlight > 0) {
        breakdown.push({
          name: "White bathroom downlight",
          qty: data.WhiteBathroomDownlight,
          price:
            simProData?.bathroomWhiteDownLight?.TotalEx *
            data.WhiteBathroomDownlight,
          time:
            simProData?.bathroomWhiteDownLight?.LaborTime *
            data.WhiteBathroomDownlight,
          prebuildIds: [simProData?.bathroomWhiteDownLight?.ID],
        });
      }

      if (data.SixInchPendantLightFitting > 0) {
        breakdown.push({
          name: "Six inch pendant light fitting",
          qty: data.SixInchPendantLightFitting,
          price:
            simProData?._6InchPendant?.TotalEx *
            data.SixInchPendantLightFitting,
          time:
            simProData?._6InchPendant?.LaborTime *
            data.SixInchPendantLightFitting,
          prebuildIds: [simProData?._6InchPendant?.ID],
        });
      }

      if (data.SatinChromeDownlight > 0) {
        breakdown.push({
          name: "Satin Chrome downlight",
          qty: data.SatinChromeDownlight,
          price:
            simProData?.satinDimmableDownLight?.TotalEx *
            data.SatinChromeDownlight,
          time:
            simProData?.satinDimmableDownLight?.LaborTime *
            data.SatinChromeDownlight,
          prebuildIds: [simProData?.satinDimmableDownLight?.ID],
        });
      }

      if (data.WhiteDownlight > 0) {
        breakdown.push({
          name: "White downlight",
          qty: data.WhiteDownlight,
          price:
            simProData?.whiteDimmableDownLight?.TotalEx * data.WhiteDownlight,
          time:
            simProData?.whiteDimmableDownLight?.LaborTime * data.WhiteDownlight,
          prebuildIds: [simProData?.whiteDimmableDownLight?.ID],
        });
      }
      if (data.LedBathroomlight > 0) {
        breakdown.push({
          name: "LED bathroom light",
          qty: data.LedBathroomlight,
          price:
            simProData?.bathroomLEDlightFitting?.TotalEx *
            data.LedBathroomlight,
          time:
            simProData?.bathroomLEDlightFitting?.LaborTime *
            data.LedBathroomlight,
          prebuildIds: [simProData?.bathroomLEDlightFitting?.ID],
        });
      }

      if (data._4InchStandardFan > 0) {
        breakdown.push({
          name: "4 inch standard fan",
          qty: data._4InchStandardFan,
          price:
            simProData?._4InchStandardFan?.TotalEx * data._4InchStandardFan,
          time:
            simProData?._4InchStandardFan?.LaborTime * data._4InchStandardFan,
          prebuildIds: [simProData?._4InchStandardFan?.ID],
        });
      }

      if (data._4InchTimerFan > 0) {
        breakdown.push({
          name: "4 inch timer fan",
          qty: data._4InchTimerFan,
          price: simProData?._4InchTimerFan?.TotalEx * data._4InchTimerFan,
          time: simProData?._4InchTimerFan?.LaborTime * data._4InchTimerFan,
          prebuildIds: [simProData?._4InchTimerFan?.ID],
        });
      }

      if (data.smokeDetector > 0) {
        breakdown.push({
          name: "Smoke detector",
          qty: data.smokeDetector,
          price: simProData?.opticalSmokeDetector?.TotalEx * data.smokeDetector,
          time:
            simProData?.opticalSmokeDetector?.LaborTime * data.smokeDetector,
          prebuildIds: [simProData?.opticalSmokeDetector?.ID],
        });
      }

      if (data.carbonDetector > 0) {
        breakdown.push({
          name: "Carbon detector",
          qty: data.carbonDetector,
          price:
            simProData?.carbonMonoxideDetector?.TotalEx * data.carbonDetector,
          time:
            simProData?.carbonMonoxideDetector?.LaborTime * data.carbonDetector,
          prebuildIds: [simProData?.carbonMonoxideDetector?.ID],
        });
      }

      if (data.COAX > 0) {
        breakdown.push({
          name: "Coax",
          qty: data.COAX,
          price: simProData?.coax?.TotalEx * data.COAX,
          time: simProData?.coax?.LaborTime * data.COAX,
          prebuildIds: [simProData?.coax?.ID],
        });
      }

      if (data.singleRJ45 > 0) {
        breakdown.push({
          name: "Single RJ45",
          qty: data.singleRJ45,
          price: simProData?.singleRJ45?.TotalEx * data.singleRJ45,
          time: simProData?.singleRJ45?.LaborTime * data.singleRJ45,
          prebuildIds: [simProData?.singleRJ45?.ID],
        });
      }

      if (data.doubleRJ45 > 0) {
        breakdown.push({
          name: "Double RJ45",
          qty: data.doubleRJ45,
          price: simProData?.doubleRJ45?.TotalEx * data.doubleRJ45,
          time: simProData?.doubleRJ45?.LaborTime * data.doubleRJ45,
          prebuildIds: [simProData?.doubleRJ45?.ID],
        });
      }

      // check items amount in breakdown array for electrical items only
      function checkTotalItems() {
        let total = [];
        breakdown.forEach((item) => {
          total.push(item.qty);
        });
        return total.map((n) => Number(n)).reduce((a, b) => a + b, 0);
      }

      // if there are items returned from checkTotalItems(), add the following items to the array
      if (checkTotalItems() > 0) {
        breakdown.push(
          {
            name: "Earth bonding",
            qty: 1,
            price: simProData?.earthBonding?.TotalEx,
            time: simProData?.earthBonding?.LaborTime,
            prebuildIds: [simProData?.earthBonding?.ID],
          },
          {
            name: "consumables",
            qty: 1,
            price: simProData?.consumables?.TotalEx,
            time: simProData?.consumables?.LaborTime,
            prebuildIds: [simProData?.consumables?.ID],
          },
          {
            name: "waste disposal",
            qty: 1,
            price: simProData?.wasteDisposal?.TotalEx,
            time: simProData?.wasteDisposal?.LaborTime,
            prebuildIds: [simProData?.wasteDisposal?.ID],
          },
          {
            name: "BWICS",
            qty: checkTotalItems(),
            price: simProData?.BWICS?.TotalEx * checkTotalItems(),
            time: simProData?.BWICS?.LaborTime * checkTotalItems(),
            prebuildIds: [simProData?.BWICS?.ID],
          }
        );
      }

      if (data.bedrooms < 3) {
        breakdown.push({
          name: "EIC certificate",
          qty: 1,
          price: simProData?.EIC8and10Way?.TotalEx,
          time: simProData?.EIC8and10Way?.LaborTime,
          prebuildIds: [simProData?.EIC8and10Way?.ID],
        });
      }

      if (data.bedrooms >= 3) {
        breakdown.push({
          name: "EIC certificate",
          qty: 1,
          price: simProData?.EIC14Way?.TotalEx,
          time: simProData?.EIC14Way?.LaborTime,
          prebuildIds: [simProData?.EIC14Way?.ID],
        });
      }

      return breakdown;
    }
    setBreakdown(calculateTotals());
    setStep(7);
    setServiceData({
      isRewiring: true,
      data,
      breakdown: calculateTotals(),
    });
    setFormDefaultValues(data);
  };

  //Conditional component to switch between journey steps
  const conditionalComponent = () => {
    switch (step) {
      case 0:
        return (
          <Bedrooms
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValue={getValues("bedrooms")}
          />
        );
      case 1:
        return (
          <Sockets
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValueSingleSocket={getValues("singleSocket")}
            defaultValueTwinSocket={getValues("twinSocket")}
          />
        );
      case 2:
        return (
          <Power
            register={register}
            watch={watch}
            setValue={setValue}
            powerFormValue={powerFormValue}
            applianceSwitchQuantity={applianceSwitchQuantity}
          />
        );
      case 3:
        return (
          <Lighting
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValueOneGangLightSwitch={getValues("OneGangTwoWaySwitch")}
            defaultValueTwoGangLightSwitch={getValues("TwoGangTwoWaySwitch")}
            defaultValueThreeGangLightSwitch={getValues(
              "ThreeGangTwoWaySwitch"
            )}
            defaultValueFourGangLightSwitch={getValues("FourGangTwoWaySwitch")}
            defaultValueOneGangDimmerSwitch={getValues("OneGangDimmerSwitch")}
            defaultValueTwoGangDimmerSwitch={getValues("TwoGangDimmerSwitch")}
            defaultValueThreeGangDimmerSwitch={getValues(
              "ThreeGangDimmerSwitch"
            )}
            defaultValueFourGangDimmerSwitch={getValues("FourGangDimmerSwitch")}
            defaultValueSatinChromeBathroomDownlight={getValues(
              "SatinChromeBathroomDownlight"
            )}
            defaultValueWhiteBathroomDownlight={getValues(
              "WhiteBathroomDownlight"
            )}
            defaultValueLedBathroomLight={getValues("LedBathroomlight")}
            defaultValueSixInchPendantLightFitting={getValues(
              "SixInchPendantLightFitting"
            )}
            defaultValueSatinChromeDownlight={getValues("SatinChromeDownlight")}
            defaultValueWhiteDownlight={getValues("WhiteDownlight")}
          />
        );
      case 4:
        return (
          <Fans
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValue4InchStandardFan={getValues("4InchStandardFan")}
            defaultValue4InchTimerFan={getValues("4InchTimerFan")}
          />
        );
      case 5:
        return (
          <SmokeCarbon
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValueCarbonDetector={getValues("carbonDetector")}
            defaultValueSmokeDetector={getValues("smokeDetector")}
            defaultValueHeatDetector={getValues("heatDetector")}
          />
        );
      case 6:
        return (
          <TvData
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValueCoax={getValues("COAX")}
            defaultValueSingleRJ45={getValues("singleRJ45")}
            defaultValueDoubleRJ45={getValues("doubleRJ45")}
          />
        );
      case 7:
        return (
          <Breakdown
            formName={REWIRING}
            breakdown={breakdown}
            watch={watch}
            setValue={setValue}
          />
        );
      default:
        return (
          <Bedrooms register={register} setStep={setStep} setValue={setValue} />
        );
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {/* Component switcher */}
        {loaded ? conditionalComponent() : <Loader />}
      </form>
    </div>
  );
}
