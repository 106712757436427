export default function TextArea({ register, ...props }) {
  return (
    <div>
      <label
        htmlFor={props.registerItem}
        className="block mb-2 text-sm font-medium text-gray-90"
      >
        {props.label}
      </label>
      <textarea
        {...register(props.registerItem)}
        id={props.registerItem}
        className="bg-white shadow-md text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 lg:text-lg resize-none"
        placeholder={props.placeholder}
        required={props.required}
      ></textarea>
    </div>
  );
}
