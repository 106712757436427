import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: window.location.href.includes("harkinsandvickers")
    ? "GTM-PLZS4RL"
    : "GTM-P4TXVZS",
};
if (
  window.location.href.includes("harkinsandvickers") ||
  window.location.href.includes("keithgunn")
) {
  TagManager.initialize(tagManagerArgs);
}
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c5ed35059a0249918185987f254d31fc@o4504886482108416.ingest.sentry.io/4504886484467712",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
